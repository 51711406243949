import { createAsyncThunk } from "@reduxjs/toolkit";
import { postAPI } from "../../axios/utils";
import { BACKEND_SERVICE_BASE_URL } from "../../utils/constants";

export const setDestination = createAsyncThunk(
    "dashboard/setDestination",
    async (data) => {
        return postAPI(`${BACKEND_SERVICE_BASE_URL}/set_destination`, data, {}, false);
    }
);

export const reportCrash = createAsyncThunk(
    "dashboard/reportCrash",
    async (data) => {
        return postAPI(`${BACKEND_SERVICE_BASE_URL}/report_crash`, data, {}, false);
    }
);

export const deleteCrash = createAsyncThunk(
    "dashboard/deleteCrash",
    async (data) => {
        return postAPI(`${BACKEND_SERVICE_BASE_URL}/delete_crash_report`, data, {}, false);
    }
);

export const saveInferenceScoreFeedback = createAsyncThunk("dashboard/saveInferenceScoreFeedback", async (data) => {
    return postAPI(`${BACKEND_SERVICE_BASE_URL}/label_record/`, data, {}, false);
});

export const getRouteBetweenPoints = createAsyncThunk("dashboard/getRouteBetweenPoints", async (data) => {
    return postAPI(`${BACKEND_SERVICE_BASE_URL}/get_route_btw_points`, data, {}, false);
});

export const reportHotspot = createAsyncThunk("dashboard/reportHotspot", async (data) => {
    return postAPI(`${BACKEND_SERVICE_BASE_URL}/save_hotspot`, data, {}, false);
});