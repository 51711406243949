import { Box, useTheme } from "@mui/material";
import React, { useState } from "react";
import { MdOutlineReport } from "react-icons/md";
import CustomIconButton from "../../../../components/CustomIconButton";
import FeedbackPopper from "./FeedbackPopper";

export default function FeedbackWidget() {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "feedback-popper" : undefined;

  return (
    <>
      <Box sx={{ position: "fixed", bottom: "3rem", right: "3rem" }}>
        <CustomIconButton
          onClick={handleClick}
          sx={{
            color: theme.palette.text.default,
            width: "56px",
            height: "56px",
            borderRadius: "50%",
            border: `2px solid ${theme.palette.primary.dark}`,
          }}
        >
          <MdOutlineReport size={34} />
        </CustomIconButton>
      </Box>
      <Box>
        <FeedbackPopper id={id} open={open} anchorEl={anchorEl} handleClick={handleClick} />
      </Box>
    </>
  );
}
