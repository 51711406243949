import { Paper as MuiPaper, styled } from "@mui/material";

const Paper = styled(MuiPaper)(({ theme }) => ({
  "& .MuiPaper-root": {},
}));

const CustomPaper = ({ ...props }) => {
  return <Paper {...props} />;
};

export default CustomPaper;
