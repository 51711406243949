import { createSlice } from "@reduxjs/toolkit";
import { KNOWN_DRIVING_ISSUES } from "../../utils/constants";
import {
  getAccidentScore,
  getFeedbackPoints,
  getGeneralInfo,
  getHotspotPoints,
  getLocationAddress,
  saveInferenceScoreFeedback,
  sendFeedBack,
} from "./coreThunk";

const initialState = {
  isLoading: false,
  vehicleLocation: null,
  anticipatedVehicleLocation: null,
  generalInfo: null,
  liveAccidentScore: 0,
  knownIssues: KNOWN_DRIVING_ISSUES,
  customTrafficDensity: null,
  feedbackPoints: [],
  hostpostPoints: [],
};

const coreSlice = createSlice({
  name: "coreSlice",
  initialState: initialState,
  reducers: {
    updateVehicleLocation: (state, action) => {
      state.vehicleLocation = action.payload;
    },
    updateAnticipatedVehicleLocation: (state, action) => {
      state.anticipatedVehicleLocation = action.payload;
    },
    updateLiveAccidentScore: (state, action) => {
      state.liveAccidentScore = action.payload;
    },
    updateKnownIssues: (state, action) => {
      state.knownIssues = action.payload;
    },
    updateGeneralInfo: (state, action) => {
      state.generalInfo = { ...state.generalInfo, ...action.payload };
    },
    updateTrafficDensity: (state, action) => {
      state.customTrafficDensity = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLocationAddress.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLocationAddress.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.vehicleLocation = action.payload.data.results[0];
      })
      .addCase(getLocationAddress.rejected, (state, action) => {
        state.isLoading = false;
      });

    builder
      .addCase(getGeneralInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getGeneralInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.generalInfo = action.payload.data;
      })
      .addCase(getGeneralInfo.rejected, (state, action) => {
        state.isLoading = false;
      });

    builder
      .addCase(getAccidentScore.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAccidentScore.fulfilled, (state, action) => {
        state.isLoading = false;
        state.liveAccidentScore = action.payload?.data?.accident_score || 0;
        state.generalInfo = action.payload?.data?.general_info || {};
      })
      .addCase(getAccidentScore.rejected, (state, action) => {
        state.isLoading = false;
      });

    builder
      .addCase(saveInferenceScoreFeedback.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveInferenceScoreFeedback.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(saveInferenceScoreFeedback.rejected, (state, action) => {
        state.isLoading = false;
      });

    builder
      .addCase(sendFeedBack.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendFeedBack.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action?.payload?.data?.object) state.feedbackPoints = [...state.feedbackPoints, action.payload.data.object];
      })
      .addCase(sendFeedBack.rejected, (state, action) => {
        state.isLoading = false;
      });

    builder
      .addCase(getFeedbackPoints.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFeedbackPoints.fulfilled, (state, action) => {
        state.isLoading = false;
        state.feedbackPoints = action.payload.data;
      })
      .addCase(getFeedbackPoints.rejected, (state, action) => {
        state.isLoading = false;
      });

    builder
      .addCase(getHotspotPoints.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHotspotPoints.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hostpostPoints = action.payload.data;
      })
      .addCase(getHotspotPoints.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default coreSlice;
export const coreActions = coreSlice.actions;
