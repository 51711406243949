import List from "@mui/material/List";
import React from "react";

import { useTheme } from "@mui/material";
import SidebarMenuItem from "./SidebarMenuItem";

export default function SidebarMenu({ sidebarMenuItems }) {
  const theme = useTheme()

  return (
    <List sx={{ width: "100%", borderRight: `2px solid ${theme.sidebarMenu.itemBorderRightColor}` }}>
      {sidebarMenuItems.map((routeDetails, index) => (
        <SidebarMenuItem routeDetails={routeDetails} key={index} />
      ))}
    </List>
  );
}
