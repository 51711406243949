import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogContent, Typography, useTheme } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomIconButton from "../../../components/CustomIconButton";
import MultiSelectAutocomplete from "../../../components/MultiSelectAutocomplete copy";
import PrimaryLoadingButton from "../../../components/PrimaryLoadingButton";
import { coreActions } from "../../../store/core/coreSlice";
import { KNOWN_DRIVING_ISSUES } from "../../../utils/constants";

export default function KnownIssuesDialog({ open, handleToggle }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [selectedData, setSelectedData] = React.useState([]);

  const handleChange = (e, data) => {
    setSelectedData(data);
  };

  return (
    <Dialog
      onClose={handleToggle}
      open={open}
      PaperProps={{
        sx: {
          background: theme.palette.background.gradientLight1,
          borderRadius: "8px",
          py: 5,
          px: 3,
          minWidth: { xs: "50vw", lg: "50vw", xl: "40vw" },
        },
      }}
    >
      <Box display="flex" justifyContent="space-between" px="20px">
        <Typography variant="h2" m="auto" color={theme.palette.text.titleLabel} sx={{ lineHeight: "100%" }}>
          Selecting Known Issues
        </Typography>
        <CustomIconButton onClick={handleToggle} sx={{ color: theme.palette.error.main }}>
          <CloseIcon />
        </CustomIconButton>
      </Box>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          pb: 0,
        }}
      >
        <Box mt={5}>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            <MultiSelectAutocomplete
              fullWidth
              value={selectedData}
              options={KNOWN_DRIVING_ISSUES.map((u, index) => ({ id: index + 1, text: u }))}
              size="small"
              id="users-select"
              label="Users"
              placeholder="Known Issues"
              onChange={handleChange}
            />
          </Box>
        </Box>
        <PrimaryLoadingButton
          isLoading={false}
          onClick={() => {
            dispatch(coreActions.updateKnownIssues(selectedData?.map((v_) => v_?.text)));
            // handleSubmit();
            handleToggle();
          }}
          sx={{
            // ...theme.typography.bigButton,
            width: "16rem",
            mx: "auto",
            mt: 6,
            mb: 1.5,
          }}
        >
          Add & Save
        </PrimaryLoadingButton>
      </DialogContent>
    </Dialog>
  );
}
