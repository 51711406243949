import WindowIcon from "@mui/icons-material/Window";
import Dashboard from "../pages/dashboard";
import Home from "../pages/home";
import Report from "../pages/report";
import Root from "../pages/root";
import Show from "../pages/show";

export const routes = {
  root: {
    path: "/",
    title: "Home",
    icon: <WindowIcon />,
    isLoginRequired: false,
    page: <Root />,
    isHiddenOnSideDrawer: true,
    isAdditional: false,
    showSideDrawer: false,
  },
  home: {
    path: "/home",
    title: "Home",
    icon: <WindowIcon />,
    isLoginRequired: false,
    page: <Home />,
    isHiddenOnSideDrawer: false,
    isAdditional: false,
    showSideDrawer: false,
  },
  feedback: {
    path: "/feedback",
    title: "Feedback",
    icon: <WindowIcon />,
    isLoginRequired: false,
    page: <Dashboard isFeedback={true} />,
    isHiddenOnSideDrawer: false,
    isAdditional: false,
    showSideDrawer: false,
  },
  dashboard: {
    path: "/dashboard",
    title: "Dashboard",
    icon: <WindowIcon />,
    isLoginRequired: false,
    page: <Dashboard />,
    isHiddenOnSideDrawer: false,
    isAdditional: false,
    showSideDrawer: false,
  },
  report: {
    path: "/report",
    title: "Report",
    icon: <WindowIcon />,
    isLoginRequired: false,
    page: <Report />,
    isHiddenOnSideDrawer: false,
    isAdditional: false,
    showSideDrawer: false,
  },
  show: {
    path: "/show",
    title: "Show",
    icon: <WindowIcon />,
    isLoginRequired: false,
    page: <Show />,
    isHiddenOnSideDrawer: false,
    isAdditional: false,
    showSideDrawer: false,
  },
};

export const routesArr = Object.entries(routes);

export const drawerMenuItems = routesArr
  .filter((route) => !route[1].isHiddenOnSideDrawer && !route[1].isAdditional)
  .map((route) => ({
    text: route[1].title,
    path: route[1].path,
    icon: route[1].icon,
  }));

export const additionalDrawerMenuItems = routesArr
  .filter((route) => !route[1].isHiddenOnSideDrawer && route[1].isAdditional)
  .map((route) => ({
    text: route[1].title,
    path: route[1].path,
    icon: route[1].icon,
  }));
