import { RADIUS_OF_EARTH } from "./constants";

export const containsText = (text, searchText) => text.toLowerCase().includes(searchText.toLowerCase());

export const convertSecondsToMMSS = (seconds) => {
  let isNegative = false;
  let secondCopy = seconds;
  if (seconds < 0) {
    secondCopy = Math.abs(seconds);
    isNegative = true;
  }
  var minutes = Math.floor(secondCopy / 60);
  var remainingSeconds = Math.floor(secondCopy % 60);

  var minutesString = minutes < 10 ? "0" + minutes : minutes.toString();
  var secondsString = remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds.toString();

  if (isNegative) {
    return `-${minutesString + ":" + secondsString}`;
  }
  return minutesString + ":" + secondsString;
};

export const randomColorHex = () => {
  // Storing all letter and digit combinations
  // for html color code
  let letters = "0123456789ABCDEF";

  // HTML color code starts with #
  let color = "#";

  // Generating 6 times as HTML color code
  // consist of 6 letter or digits
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const formatDatetimeToYYYYMMDD = (date, range) => {
  // // Create a new Date object
  const dateObj = new Date(date);
  if (range) {
    dateObj.setDate(dateObj.getDate() - 1);
  } else {
    dateObj.setDate(dateObj.getDate() + 1);
  }
  const updatedDate = new Date(dateObj);

  // GetUTC the year, month, and day from the Date object
  const year = updatedDate.getFullYear().toString().padStart(4, "0");
  const month = (updatedDate.getMonth() + 1).toString().padStart(2, "0"); // Note: month starts from 0
  const day = updatedDate.getDate().toString().padStart(2, "0");

  // Combine the year, month, and day into the desired format
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const formatDatetimeToDDMMYYYY = (date) => {
  const updatedDate = new Date(date);

  const year = updatedDate.getFullYear().toString().padStart(4, "0");
  const month = (updatedDate.getMonth() + 1).toString().padStart(2, "0");
  const day = updatedDate.getDate().toString().padStart(2, "0");

  return `${day}-${month}-${year}`;
};

export const formatDateTime = (dateTimeString) => {
  const dateTime = new Date(dateTimeString);

  // Format date in "21 Jul 2023" format
  const options = { day: "numeric", month: "short", year: "numeric" };
  const date = dateTime.toLocaleDateString("en-IN", options);

  // Manually format the time in "hh:mm" format
  const hours = dateTime.getUTCHours().toString().padStart(2, "0");
  const minutes = dateTime.getUTCMinutes().toString().padStart(2, "0");
  const seconds = dateTime.getUTCSeconds().toString().padStart(2, "0");

  const time = `${hours}:${minutes}:${seconds}`;

  return { date, time };
};

export const formatDate = (dateTimeString) => {
  const dateTime = new Date(dateTimeString);

  // Format date in "21 Jul 2023" format
  const options = { day: "numeric", month: "short", year: "numeric" };
  const date = dateTime.toLocaleDateString("en-IN", options);

  // Manually format the time in "hh:mm" format
  const hours = dateTime.getHours().toString().padStart(2, "0");
  const minutes = dateTime.getMinutes().toString().padStart(2, "0");
  const time = `${hours}:${minutes}`;

  return { date, time };
};

// Get specific duration format for a given time period
export const getLicenceDuration = (duration) => {
  if (duration < 12 && duration > 1) return `${duration} months`;
  else if (duration === 12) {
    const year = duration % 12;
    if (year > 1) {
      return `${year} year`;
    } else {
      return `${year} years`;
    }
  } else if (duration === 1) return `${duration} month`;
  else return "";
};

export const getCurrentDateTime = (dateTimeString = "") => {
  const date = dateTimeString === "" ? new Date() : new Date(dateTimeString);
  const day = String(date.getDate()).padStart(2, "0");
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const month = monthNames[date.getMonth()];
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${day} ${month} ${hours}:${minutes}:${seconds}`;
};

export const getCurrentDateTimeWithYear = (dateTimeString = "") => {
  const date = dateTimeString === "" ? new Date() : new Date(dateTimeString);
  const day = String(date.getDate()).padStart(2, "0");
  const year = String(date.getFullYear());
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const month = monthNames[date.getMonth()];
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${day} ${month} ${year} ${hours}:${minutes}:${seconds}`;
};

export const getLocalDateTime = (utcDateTimeString, type = "datetime") => {
  const dateOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  const timeOptions = {
    hour12: false,
    hour: "numeric",
    minute: "numeric",
  };
  const fullTimeOptions = {
    hour12: false,
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  const shortDateOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  };

  if (type === "datetime") {
    const formattedLocalDateTime = new Date(utcDateTimeString).toLocaleString(undefined, {
      ...dateOptions,
      ...timeOptions,
    });
    return formattedLocalDateTime;
  } else if (type === "date") {
    const formattedLocalDateTime = new Date(utcDateTimeString);
    return `${formattedLocalDateTime.getDate().toString().padStart(2, "0")}.${(formattedLocalDateTime.getMonth() + 1)
      .toString()
      .padStart(2, "0")}.${formattedLocalDateTime.getFullYear().toString().padStart(2, "0")}`;
  } else if (type === "time") {
    const formattedLocalDateTime = new Date(utcDateTimeString).toLocaleString(undefined, timeOptions);
    return formattedLocalDateTime;
  } else if (type === "timewithSeconds") {
    const formattedLocalDateTime = new Date(utcDateTimeString).toLocaleString(undefined, fullTimeOptions);
    return formattedLocalDateTime;
  } else if (type === "session") {
    const startTime = utcDateTimeString?.slice(0, 5);
    const endTime = utcDateTimeString?.slice(8, 13);
    const startDate = new Date();
    startDate.setUTCHours(startTime?.slice(0, 2));
    startDate.setUTCMinutes(startTime?.slice(3, 5));
    const endDate = new Date();
    endDate.setUTCHours(endTime?.slice(0, 2));
    endDate.setUTCMinutes(endTime?.slice(3, 5));
    return `${startDate?.toLocaleTimeString([], timeOptions)} - ${endDate?.toLocaleTimeString([], timeOptions)}`;
  } else if (type === "shortDate") {
    const formattedLocalDateTime = new Date(utcDateTimeString);
    return `${formattedLocalDateTime.getDate().toString().padStart(2, "0")}.${(formattedLocalDateTime.getMonth() + 1)
      .toString()
      .padStart(2, "0")}.${formattedLocalDateTime.getFullYear().toString().substr(-2)}`;
  } else if (type === "dateWithWordMonth") {
    const formattedLocalDateTime = new Date();
    formattedLocalDateTime.setUTCDate(utcDateTimeString?.slice(8, 10));
    formattedLocalDateTime.setUTCMonth(utcDateTimeString?.slice(5, 7) - 1);
    formattedLocalDateTime.setUTCFullYear(utcDateTimeString?.slice(0, 4));
    var monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return `${formattedLocalDateTime.getDate().toString().padStart(2, "0")} ${monthShortNames[formattedLocalDateTime.getMonth()]
      .toString()
      .padStart(2, "0")} ${formattedLocalDateTime.getFullYear().toString()}`;
  }
};

export const formatDatetime = (date) => {
  const dateTime = new Date(date);
  return `${dateTime.getUTCFullYear().toString().padStart(2, "0")}-${(dateTime.getUTCMonth() + 1).toString().padStart(2, "0")}-${dateTime
    .getUTCDate()
    .toString()
    .padStart(2, "0")} ${dateTime.getUTCHours().toString().padStart(2, "0")}:${dateTime.getUTCMinutes().toString().padStart(2, "0")}:${dateTime
    .getUTCSeconds()
    .toString()
    .padStart(2, "0")}`;
};

export const formatDatetimeIntoLocal = (date) => {
  const dateTime = new Date(date);
  return `${dateTime.getFullYear().toString().padStart(2, "0")}-${(dateTime.getMonth() + 1).toString().padStart(2, "0")}-${dateTime
    .getDate()
    .toString()
    .padStart(2, "0")} ${dateTime.getHours().toString().padStart(2, "0")}:${dateTime.getMinutes().toString().padStart(2, "0")}:${dateTime
    .getSeconds()
    .toString()
    .padStart(2, "0")}`;
};

export const textTruncate = (str, length) => {
  return str.length > length ? str.substring(0, length) + "..." : str;
};

export const subtractHours = (date, hours) => {
  const dateCopy = new Date(date);
  dateCopy.setHours(dateCopy.getHours() - hours);
  return dateCopy;
};

export const formatDateTimeIntoBackenStandard = (dateTime = new Date()) => {
  const year = dateTime.getUTCFullYear();
  const month = String(dateTime.getUTCMonth() + 1).padStart(2, "0");
  const day = String(dateTime.getUTCDate()).padStart(2, "0");
  const hours = String(dateTime.getUTCHours()).padStart(2, "0");
  const minutes = String(dateTime.getUTCMinutes()).padStart(2, "0");
  const seconds = String(dateTime.getUTCSeconds()).padStart(2, "0");
  const milliseconds = String(dateTime.getUTCMilliseconds()).padStart(3, "0");

  const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  return formattedDateTime;
};

export const formatDateTimeIntoBackendStandard = (dateTime = new Date()) => {
  const year = dateTime.getFullYear();
  const month = String(dateTime.getMonth() + 1).padStart(2, "0");
  const day = String(dateTime.getDate()).padStart(2, "0");
  const hours = String(dateTime.getHours()).padStart(2, "0");
  const minutes = String(dateTime.getMinutes()).padStart(2, "0");
  const seconds = String(dateTime.getSeconds()).padStart(2, "0");
  const milliseconds = String(dateTime.getMilliseconds()).padStart(3, "0");

  const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  return formattedDateTime;
};

export const getDateTimeString = (dateString) => {
  const date = new Date(dateString);
  let dateMonth = `${date.getMonth() + 1}`;
  let dateDay = `${date.getDate()}`;
  let month = dateMonth.length > 1 ? dateMonth : `0${dateMonth}`;
  let day = dateDay.length > 0 ? dateDay : `0${dateDay}`;
  let time = new Date(dateString).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  });
  let formatedDate = `${day}-${month}-${date.getFullYear()} ${time}`;
  return `${formatedDate} `;
};

function safeParseFloat(str) {
  // Check if the string contains only digits and optionally a single decimal point
  if (/^[\d]+(?:\.[\d]+)?$/.test(str)) {
    // If the string is valid, parse it as a float
    return parseFloat(str);
  } else {
    // If the string is not valid, return NaN or handle the error as appropriate
    return NaN;
  }
}

export const convertValuesToNumber = (inputObject) => {
  // Check if the input is an object
  if (typeof inputObject !== "object" || inputObject === null) {
    throw new Error("Input must be an object");
  }

  // Iterate through the properties of the object
  for (const key in inputObject) {
    if (inputObject.hasOwnProperty(key) && typeof inputObject[key] === "string") {
      // Attempt to parse the value to a number
      const parsedValue = safeParseFloat(inputObject[key]);

      // Check if the parsing was successful and the result is not NaN
      if (!isNaN(parsedValue)) {
        // Convert the value to a number
        inputObject[key] = parsedValue;
      }
    }
  }

  // Return the modified object
  return inputObject;
};
export function rangeArea(location , dist) {
  // calculate range of a given location

  const lower_offset = -1*dist; // offset in metre
  const upper_offset = dist;

  const Rad_up = upper_offset / RADIUS_OF_EARTH;
  const Rad_down = lower_offset / RADIUS_OF_EARTH;

  const lat_upper = location.latitude + (Rad_up * 180) / Math.PI;
  const lat_lower = location.latitude + (Rad_down * 180) / Math.PI;

  const lng_upper = location.longitude + (Rad_up * 180) / Math.PI;
  const lng_lower = location.longitude + (Rad_down * 180) / Math.PI;

  const rangePoint = {
    lat_upper: lat_upper,
    lat_lower: lat_lower,
    lng_upper: lng_upper,
    lng_lower: lng_lower,
  };
  return rangePoint;
}