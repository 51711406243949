// export const PROTOCOL = process.env.REACT_APP_PROTOCOL || "https";
// export const BASE_HOST = process.env.REACT_APP_BASE_HOST || "mercedes.server.skylarklabs.ai";
// export const WS_BASE_URL = process.env.REACT_APP_WS_BASE_URL || "wss://mercedes.server.skylarklabs.ai"; // If you are defining explicit host, dont forget to mention post as well, eg. 127.0.0.1:5000
export const PROTOCOL = "https";
export const BASE_HOST = "mercedes.server.skylarklabs.ai";
export const WS_BASE_URL = "https://mercedes.server.skylarklabs.ai";
export const BACKEND_SERVICE_BASE_URL = `${PROTOCOL}://${BASE_HOST}`;
export const GOOGLE_MAP_REACT_API_KEY = "AIzaSyBMhmpCz_ZjrIjhMjmfqmI591s1zgHjiyE";

export const TESTING = true;

export const KNOWN_DRIVING_ISSUES = ["Low Visibility", "Slippery Roads", "Speeding", "Blinding Spots", "Heavy Traffic Intersection", "Obstruction"];

export const RADIUS_OF_EARTH = 6378137;

export const TYPE_CHOICES = ["Road Maintenance", "Accidents", "Road Hazards", "Road Closures", "Construction"];
