import { Box, Grid, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from 'react';
import MyMapComponent from "./components/MyMapComponent";

const Show = () => {
    const theme = useTheme();
    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} sx={{ display: "flex", justifyContent: "flex-start", gap: 2 }}>
                    <Box>
                        <img src="/skylarklab_logo_black.png" alt="logo" width={"50px"} />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>
                        <Typography variant="h1">Accident Hotspot Detection</Typography>
                        <Typography variant="h4" fontWeight="bold" color={theme.palette.primary.main}>
                            Powered by Skylark Labs
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>

                </Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item xs={12} md={12} sx={{ height: ["70vh", "80vh"] }}>
                    <Paper
                        sx={{
                            background: theme.palette.background.default,
                            boxShadow: theme.dashboardContainer.insetBoxShadow,
                            borderRadius: "18px",
                            paddingTop: "0px",
                            height: "100%",
                            overflowY: "scroll",
                            py: 2,
                            px: 2,
                            [theme.breakpoints.down("md")]: { boxShadow: "none" },
                        }}
                    >
                        <MyMapComponent />
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Show;