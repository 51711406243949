import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout";
import { Button, useTheme } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomDivider from "../../../components/CustomDivider";
import { routes } from "../../../routes";
import Logo from "./Logo";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function DrawerMenu({ sidebarMenuItems }) {
  const theme = useTheme();

  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);

  const handleRouteChange = (path) => {
    setOpen((prev) => !prev);
    navigate(path);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar
          sx={{
            background: theme.palette.background.paper,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            color={theme.palette.text.default}
            aria-label="open drawer"
            onClick={() => setOpen((prev) => !prev)}
            edge="start"
            sx={{ mr: 2, ...(open && { visibility: "hidden" }) }}
          >
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="heroicons-solid:menu">
                <path
                  id="Vector"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.80078 7.9999C4.80078 7.57556 4.96935 7.16859 5.26941 6.86853C5.56947 6.56847 5.97643 6.3999 6.40078 6.3999H25.6008C26.0251 6.3999 26.4321 6.56847 26.7322 6.86853C27.0322 7.16859 27.2008 7.57556 27.2008 7.9999C27.2008 8.42425 27.0322 8.83121 26.7322 9.13127C26.4321 9.43133 26.0251 9.5999 25.6008 9.5999H6.40078C5.97643 9.5999 5.56947 9.43133 5.26941 9.13127C4.96935 8.83121 4.80078 8.42425 4.80078 7.9999ZM4.80078 15.9999C4.80078 15.5756 4.96935 15.1686 5.26941 14.8685C5.56947 14.5685 5.97643 14.3999 6.40078 14.3999H25.6008C26.0251 14.3999 26.4321 14.5685 26.7322 14.8685C27.0322 15.1686 27.2008 15.5756 27.2008 15.9999C27.2008 16.4242 27.0322 16.8312 26.7322 17.1313C26.4321 17.4313 26.0251 17.5999 25.6008 17.5999H6.40078C5.97643 17.5999 5.56947 17.4313 5.26941 17.1313C4.96935 16.8312 4.80078 16.4242 4.80078 15.9999ZM4.80078 23.9999C4.80078 23.5756 4.96935 23.1686 5.26941 22.8685C5.56947 22.5685 5.97643 22.3999 6.40078 22.3999H25.6008C26.0251 22.3999 26.4321 22.5685 26.7322 22.8685C27.0322 23.1686 27.2008 23.5756 27.2008 23.9999C27.2008 24.4242 27.0322 24.8312 26.7322 25.1313C26.4321 25.4313 26.0251 25.5999 25.6008 25.5999H6.40078C5.97643 25.5999 5.56947 25.4313 5.26941 25.1313C4.96935 24.8312 4.80078 24.4242 4.80078 23.9999Z"
                  fill={theme.palette.text.default}
                />
              </g>
            </svg>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          flexShrink: 0,
          backgroundColor: theme.palette.background.paper,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
        PaperProps={{
          style: {
            width: "100%",
            padding: "10px",
            maxHeight: "100%",
            overflowY: "scroll",
          },
        }}
      >
        <Box display={"flex"} justifyContent={"space-between"} mb={10}>
          <DrawerHeader>
            <Logo />
          </DrawerHeader>
          <DrawerHeader>
            <Button
              variant="contained"
              onClick={() => {
                setOpen((prev) => !prev);
              }}
              sx={{
                background: theme.dashboardContainer.closeButtonColor,
                boxShadow: theme.dashboardContainer.closeButtonBoxShadow,
                borderRadius: "4px",
                minWidth: (theme) => theme.spacing(3),
                width: (theme) => theme.spacing(3),
                height: (theme) => theme.spacing(3),
                padding: "6px",
                color: theme.palette.error.main,
              }}
            >
              <CloseIcon />
            </Button>
          </DrawerHeader>
        </Box>
        <CustomDivider />
        <List sx={{ marginBottom: "20px" }}>
          {sidebarMenuItems.map(
            (route, index) =>
              route.text !== "Logout" && (
                <React.Fragment key={route.text}>
                  <ListItemButton
                    disableRipple
                    disableTouchRipple
                    onClick={() => {
                      handleRouteChange(`${route.path}`);
                    }}
                  >
                    <ListItem key={index} disablePadding sx={{ marginY: "10px" }}>
                      <ListItemButton sx={{ display: "flex", alignItems: "center" }}>
                        <ListItemIcon
                          sx={{
                            color: () => (location.pathname.match(route.path) ? theme.palette.primary.main : theme.palette.text.menuColor),
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                        >
                          {route.icon}
                        </ListItemIcon>
                        <Typography
                          sx={{
                            marginLeft: (theme) => (location.pathname.match(route.path) ? `-${theme.spacing(2)}` : "none"),
                          }}
                          variant="h1"
                          color={location.pathname.match(route.path) ? (theme) => theme.palette.primary.main : theme.palette.text.menuColor}
                        >
                          {route.text}
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                  </ListItemButton>
                  <CustomDivider />
                </React.Fragment>
              ),
          )}
        </List>
        <Box
          sx={{
            overflowX: "hidden",
            position: "relative",
          }}
        >
          <IconButton
            onClick={() => navigate(routes.logout.path)}
            sx={{
              display: "flex",
              padding: "22px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              flexShrink: 0,
              borderRadius: "53px",
              boxShadow: theme.dashboardContainer.themeButtonBoxShadow,
            }}
          >
            <LogoutIcon sx={{ color: theme.palette.text.titleLabel, transform: "rotate(180deg)" }} />
            <Typography sx={{ color: theme.palette.text.titleLabel }}>Logout</Typography>
          </IconButton>
          <img
            src="/skylarklabs_circle.png"
            alt=""
            style={{ position: "absolute", width: "1000px", bottom: "662px", left: "-472px", rotate: "-101deg", zIndex: "-1" }}
          />
        </Box>
      </Drawer>
    </Box>
  );
}
