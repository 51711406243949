import { Box } from "@mui/material";
import React from "react";

export default function BaseLayout({ children }) {
  return (
    <>
      <Box
      // sx={(theme) => ({
      //   [theme.breakpoints.down("md")]: {
      //     marginTop: "60px",
      //     marginLeft: "10px",
      //   },
      // })}
      >
        {children}
      </Box>
    </>
  );
}
