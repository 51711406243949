import { Box, Grid, Paper, Typography, useTheme } from '@mui/material';
import { Autocomplete, LoadScript } from "@react-google-maps/api";
import { Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from "yup";
import CustomFormButton from '../../components/CustomFormButton.js';
import FormikInputField from '../../components/FormikInputField.js';
import FormikRadioField from '../../components/FormikRadioField.js';
import PrimaryLoadingButton from '../../components/PrimaryLoadingButton';
import { getRouteBetweenPoints, reportHotspot } from '../../store/dashboard/dashboardThunk';
import { GOOGLE_MAP_REACT_API_KEY } from '../../utils/constants';
import MyMapComponent from './components/MyMapComponent';

const libraries = ["places", "drawing"];

const initialValues = {
    Hotspot: "",
    Description: "",
};

const formField = {
    Hotspot: {
        name: "Hotspot",
        placeholder: "Enter the Hotspot Name",
        requiredErrorMsg: "Hotspot Name is required.",
    },
    Description: {
        name: "Description",
        placeholder: "Enter the Description",
        requiredErrorMsg: "Description is required.",
    },
};

const validationSchema = Yup.object().shape({
    [formField.Hotspot.name]: Yup.string().required(formField.Hotspot.requiredErrorMsg),
});

const Report = () => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const [fromAutocomplete, setFromAutocomplete] = useState(null);
    const [toAutocomplete, setToAutocomplete] = useState(null);
    const [fromCoords, setFromCoords] = useState(null);
    const [toCoords, setToCoords] = useState(null);
    const [coOrdinatesData, setCoOrdinatesData] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [formData, setFormData] = React.useState(initialValues);
    const [selectedDescription, setSelectedDescription] = useState(["Heavy Traffic Volume"]);

    const fromOnLoad = useCallback((autocompleteInstance) => {
        setFromAutocomplete(autocompleteInstance);
    }, []);

    const toOnLoad = useCallback((autocompleteInstance) => {
        setToAutocomplete(autocompleteInstance);
    }, []);

    useEffect(() => {
        handleInputChange({
            fieldName: formField.Description.name,
            value: "Heavy Traffic Volume",
        });
    }, []);

    const onFromPlaceChanged = useCallback(() => {
        if (fromAutocomplete !== null) {
            const place = fromAutocomplete.getPlace();
            if (!place.geometry) {
                console.log("Returned place contains no geometry");
                return;
            }
            setFromCoords([place.geometry.location.lat(), place.geometry.location.lng()]);
            handleInputChange({ fieldName: "from", value: [place.geometry.location.lat(), place.geometry.location.lng()] });
        } else {
            console.log("fromAutocomplete is not loaded yet!");
        }
    }, [fromAutocomplete]);

    const onToPlaceChanged = useCallback(() => {
        if (toAutocomplete !== null) {
            const place = toAutocomplete.getPlace();
            if (!place.geometry) {
                console.log("Returned place contains no geometry");
                return;
            }
            setToCoords([place.geometry.location.lat(), place.geometry.location.lng()]);
            handleInputChange({ fieldName: "to", value: [place.geometry.location.lat(), place.geometry.location.lng()] });
        } else {
            console.log("toAutocomplete is not loaded yet!");
        }
    }, [toAutocomplete]);

    const getRouteOnClick = () => {
        if (fromCoords && toCoords) {
            dispatch(getRouteBetweenPoints(
                {
                    origin_lat: fromCoords[0],
                    origin_lng: fromCoords[1],
                    destination_lat: toCoords[0],
                    destination_lng: toCoords[1]
                }
            )).then((res) => {
                if (res.payload?.status === 200) {
                    setCoOrdinatesData(res.payload.data.route_coordinates);
                } else {
                    toast.error("Error fetching route between points!");
                }
            }
            ).catch((err) => {
                toast.error("Error fetching route between points!");
            }
            );
        } else {
            toast.error("Please select both From and To places!");
        }
    };

    const handleInputChange = ({ fieldName, value }) => {
        setFormData((prev) => ({
            ...prev,
            [fieldName]: value,
        }));
    };

    const handleSubmit = () => {
        dispatch(reportHotspot(formData)).then((res) => {
            if (res.payload?.status === 201) {
                toast.success("Hotspot reported successfully!");
                setShowForm(false);
                setFormData(initialValues);
            } else {
                toast.error("Error reporting hotspot!");
            }
        }).catch((err) => {
            toast.error("Error reporting hotspot!");
        });
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} sx={{ display: "flex", justifyContent: "flex-start", gap: 2 }}>
                    <Box>
                        <img src="/skylarklab_logo_black.png" alt="logo" width={"50px"} />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>
                        <Typography variant="h1">Accident Hotspot Detection</Typography>
                        <Typography variant="h4" fontWeight="bold" color={theme.palette.primary.main}>
                            Powered by Skylark Labs
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>

                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8} style={{ display: "flex", justifyContent: "center" }}>
                    <LoadScript googleMapsApiKey={GOOGLE_MAP_REACT_API_KEY} libraries={libraries}>
                        <Box
                            className="autocomplete-control"
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Box className="autocomplete-container" sx={{ minWidth: "16rem", maxWidth: "min-content" }}>
                                <Autocomplete onLoad={fromOnLoad} onPlaceChanged={onFromPlaceChanged}>
                                    <input
                                        type="text"
                                        placeholder="Search for From place"
                                        style={{
                                            boxSizing: `border-box`,
                                            border: `1px solid transparent`,
                                            width: `240px`,
                                            height: `32px`,
                                            padding: `28px`,
                                            borderRadius: `8px`,
                                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                            fontSize: `16px`,
                                            outline: `none`,
                                            textOverflow: `ellipses`,
                                            background: "rgb(29, 35, 48)",
                                            color: "white",
                                        }}
                                    />
                                </Autocomplete>
                            </Box>
                        </Box>
                        <Box
                            className="autocomplete-control"
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Box className="autocomplete-container" sx={{ minWidth: "16rem", maxWidth: "min-content" }}>
                                <Autocomplete onLoad={toOnLoad} onPlaceChanged={onToPlaceChanged}>
                                    <input
                                        type="text"
                                        placeholder="Search for To place"
                                        style={{
                                            boxSizing: `border-box`,
                                            border: `1px solid transparent`,
                                            width: `240px`,
                                            height: `32px`,
                                            padding: `28px`,
                                            borderRadius: `8px`,
                                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                            fontSize: `16px`,
                                            outline: `none`,
                                            textOverflow: `ellipses`,
                                            background: "rgb(29, 35, 48)",
                                            color: "white",
                                        }}
                                    />
                                </Autocomplete>
                            </Box>
                        </Box>
                        <PrimaryLoadingButton sx={{ height: "fit-content", alignSelf: "center" }} onClick={getRouteOnClick}>Get</PrimaryLoadingButton>
                    </LoadScript>
                </Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item xs={12} md={8} sx={{ height: ["60vh", "70vh"] }}>
                    <Paper
                        sx={{
                            background: theme.palette.background.default,
                            boxShadow: theme.dashboardContainer.insetBoxShadow,
                            borderRadius: "18px",
                            paddingTop: "0px",
                            height: "100%",
                            overflowY: "scroll",
                            py: 2,
                            px: 2,
                            [theme.breakpoints.down("md")]: { boxShadow: "none" },
                        }}
                    >
                        <MyMapComponent coOrdinates={coOrdinatesData} fromCoords={fromCoords} toCoords={toCoords} setShowForm={setShowForm} handleInputChange={handleInputChange} />
                    </Paper>
                </Grid>
                {showForm && (<Grid item xs={12} md={4}>
                    <Paper
                        sx={{
                            background: theme.palette.background.default,
                            boxShadow: theme.dashboardContainer.insetBoxShadow,
                            borderRadius: "18px",
                            paddingTop: "0px",
                            height: "100%",
                            overflowY: "scroll",
                            py: 2,
                            px: 2,
                            [theme.breakpoints.down("md")]: { boxShadow: "none" },
                        }}
                    >
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={() => {
                                handleSubmit();
                            }}
                        >
                            {(formikProps) => (
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        formikProps.handleSubmit();
                                    }}
                                >
                                    <Box display="flex" flexDirection="column" justifyContent="space-between" sx={{ width: "100%" }}>
                                        <Box mt={10}>
                                            <Typography
                                                variant="body3"
                                                sx={{
                                                    color: "#A0ADB8",
                                                    textAlign: "center",
                                                }}
                                            >
                                                {formField.Hotspot.placeholder}
                                            </Typography>
                                            <FormikInputField
                                                sx={{
                                                    mt: 1,
                                                }}
                                                onChange={(e) => {
                                                    formikProps.handleChange(e);
                                                    handleInputChange({
                                                        fieldName: formField.Hotspot.name,
                                                        value: e.target.value,
                                                    });
                                                }}
                                                name={formField.Hotspot.name}
                                                placeholder={formField.Hotspot.placeholder}
                                                inputProps={{
                                                    style: {
                                                        borderRadius: "6px",
                                                        boxShadow: theme.dashboardContainer.insetBoxShadow,
                                                    },
                                                }}
                                            />
                                        </Box>
                                        <Box mt={2}>
                                            <Typography
                                                variant="body3"
                                                sx={{
                                                    color: "#A0ADB8",
                                                    textAlign: "center",
                                                }}
                                            >
                                                {formField.Description.placeholder}
                                            </Typography>
                                            <FormikRadioField
                                                onChange={(e) => {
                                                    formikProps.handleChange(e);
                                                    setSelectedDescription(e.target.value);
                                                    handleInputChange({
                                                        fieldName: formField.Description.name,
                                                        value: e.target.value,
                                                    });
                                                }}
                                                name={formField.Description.name}
                                                placeholder={formField.Description.placeholder}
                                                inputProps={{
                                                    style: {
                                                        borderRadius: "6px",
                                                        boxShadow: theme.dashboardContainer.insetBoxShadow,
                                                    },
                                                }}
                                                options={[
                                                    { value: "Heavy Traffic Volume", label: "Heavy Traffic Volume" },
                                                    { value: "Poor Road Conditions", label: "Poor Road Conditions" },
                                                    { value: "Sharp Turns and Curves", label: "Sharp Turns and Curves" },
                                                    { value: "Inadequate Signage", label: "Inadequate Signage" },
                                                    { value: "Intersections and Junctions", label: "Intersections and Junctions" },
                                                    { value: "Pedestrian Crossings", label: "Pedestrian Crossings" },
                                                    { value: "Speeding Zones", label: "Speeding Zones" },
                                                    { value: "Visibility Issues", label: "Visibility Issues" },
                                                    { value: "Frequent Lane Changes", label: "Frequent Lane Changes" },
                                                    { value: "Road Construction Zones", label: "Road Construction Zones" },
                                                    { value: "High Incidence of Drunk Driving", label: "High Incidence of Drunk Driving" },
                                                    { value: "High Volume of Transport Vehicles", label: "High Volume of Transport Vehicles" },
                                                    { value: "Weather Conditions", label: "Weather Conditions" }
                                                ]}
                                                value={selectedDescription}
                                                sx={{
                                                    mt: 1,
                                                    "& .MuiSelect-select": { fontSize: "16px", padding: "8px" },
                                                }}
                                            />
                                        </Box>
                                        <Box display="flex" justifyContent="center" mt={3}>
                                            <CustomFormButton size="small" type="submit">Submit</CustomFormButton>
                                        </Box>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Paper>
                </Grid>)}
            </Grid>
        </Box>
    );
}

export default Report;