import { Box, MenuItem } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import CustomDivider from "./CustomDivider";

export default function RadioDropdownMenuItem({ children, ...props }) {
  const { isDarkTheme } = useSelector((state) => state.theme);

  return (
    <>
      <MenuItem {...props} sx={(theme) => ({ py: 1.5, color: theme.palette.text.titleLabel, fontSize: "14px" })}>
        {props.selected ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img src={isDarkTheme ? "/dropdown_radio.png" : "/light_radio_dropdown.png"} />
          </Box>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img src="/dropdown_radio_unselected.png" />
          </Box>
        )}
        &nbsp;&nbsp;{children}
      </MenuItem>
      <CustomDivider />
    </>
  );
}
