export const sxTruncateText = {
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
};

export const sxAccordionDetails = {
  padding: "0px",
  paddingTop: "15px",
  backgroundColor: (theme) => theme.palette.background.default,
  paddingBottom: "15px",
};

export const sxTableCell = {
  fontSize: "14px",
  color: (theme) => theme.palette.text.tableHeader,
  borderBottom: (theme) => `1px solid${theme.dashboardContainer.tableBorder}`,
  textAlign: "left",
};

export const sxTableRow = {
  borderBottom: (theme) => `1px solid${theme.dashboardContainer.tableBorder}`,
};

export const sxNoBorderBottomRow = {
  borderBottom: "none",
};

export const sxTableCellBody = {
  borderBottom: (theme) => `1px solid${theme.dashboardContainer.tableBorder}`,
  textAlign: "left",
  fontSize: "16px",
  fontWeight: 400,
};

export const sxTableCellCenter = {
  fontSize: "14px",
  color: "#5A80AA",
  borderBottom: (theme) => `1px solid${theme.dashboardContainer.tableBorder}`,
  textAlign: "center",
};

export const sxTableCellBodyCenter = {
  borderBottom: "1px solid #41527D",
  textAlign: "center",
  fontSize: "16px",
};

export const sxButtonStyle = {
  color: (theme) => theme.palette.primary.main,
  border: "none",
  background: "linear-gradient(316.03deg, #252E43 0%, #111722 90%)",
  boxShadow: "-4px -4px 6px rgba(48, 62, 90, 0.55), 10px 10px 20px #12161D",
  borderRadius: "4px",
};


export const sxCenter = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const sxCheckbox = (selected) => {
  return {
    fontSize: "14px",
    fontWeight: "400",
    "& .MuiSvgIcon-root": {
      fill: (theme) => (selected ? theme.palette.checkbox.default : theme.palette.checkbox.darkBorder),
    },
    "& .MuiButtonBase-root.MuiCheckbox-root": {
      pl: "5px",
    },
    "&:hover .MuiSvgIcon-root": {
      fill: (theme) =>
        selected ? theme.palette.checkbox.default : "linear-gradient(135deg, rgba(38, 46, 63, 0.70) 0%, rgba(29, 35, 48, 0.70) 100%)",
      stroke: (theme) => (selected ? "none" : theme.palette.checkbox.darkBorder),
    },
    "&:active .MuiSvgIcon-root": {
      fill: (theme) => (selected ? "#245785" : theme.palette.checkbox.darkBorder),
    },
  };
};

export const sxThemeSwitch = {
  position: "absolute",
  right: "10px",
  top: "10px",
  zIndex: 1,
  "& .MuiSwitch-switchBase": {
    my: 1,
    ml: "6px",
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#000",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: (theme) =>
          `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            `${theme.palette.background.default}`,
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        background: (theme) => theme.palette.background.dark,
        boxShadow: (theme) => theme.dashboardContainer.customSwitchBoxShadow,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: (theme) => theme.palette.primary.main,
    width: 20,
    height: 20,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        `#fff`,
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    background: (theme) => theme.palette.background.dark,
    boxShadow: (theme) => theme.dashboardContainer.customSwitchBoxShadow,
    borderRadius: "20px",
  },
};
