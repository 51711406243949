import React from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../routes";

export default function Root() {
  const navigate = useNavigate();
  navigate(routes.dashboard.path);

  React.useEffect(() => {
    navigate(routes.dashboard.path);
  }, []);

  return <></>;
}
