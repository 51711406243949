import ClearIcon from "@mui/icons-material/Clear";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Autocomplete, Box, Checkbox, MenuItem, useTheme } from "@mui/material";
import React from "react";
import CustomInputField from "./CustomInputField";

import { sxCheckbox } from "../utils/sxStyles";
import BaseInput from "./BaseInput";
import CustomDivider from "./CustomDivider";

const MultiSelectAutocomplete = ({ options, placeholder, defaultValue, ...rest }) => {
  const theme = useTheme();
  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => option.id === value.id}
      multiple
      popupIcon={<KeyboardArrowDownIcon />}
      options={options}
      getOptionLabel={(option) => option.text}
      defaultValue={defaultValue}
      disableCloseOnSelect
      ChipProps={{
        sx: {
          background: theme.palette.primary.tertiary,
          borderRadius: "4px",
          color: theme.palette.error.btnText,
          "& .MuiInputBase-adornedEnd .MuiSvgIcon-root": { color: theme.palette.error.btnText },
        },
        deleteIcon: <ClearIcon />,
      }}
      renderOption={(props, option, { selected }) => {
        return (
          <Box
            sx={{
              px: 2,
            }}
          >
            <MenuItem
              key={option.id}
              sx={{
                ...theme.typography.body4,
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                background: "transparent !important",
                color: theme.palette.text.titleLabel,
              }}
              {...props}
            >
              <Checkbox
                checked={selected}
                sx={{
                  p: 0,
                  ...sxCheckbox(selected),
                  "& .MuiButtonBase-root.MuiCheckbox-root": {
                    pl: 0,
                    ml: "auto",
                  },
                }}
              />
              {option?.text ? option.text : option}
            </MenuItem>
            <CustomDivider />
          </Box>
        );
      }}
      renderInput={(params) => <CustomInputField {...params} variant="outlined" placeholder={placeholder} />}
      input={<BaseInput />}
      {...rest}
    />
  );
};

export default MultiSelectAutocomplete;
