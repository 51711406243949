import { Typography } from "@mui/material";
import { Field, useField } from "formik";
import RadioDropdown from "./RadioDropdown";
import RadioDropdownMenuItem from "./RadioDropdownMenuItem";

export default function FormikRadioField(props) {
    const { errorText, ...rest } = props;
    const [field, meta] = useField(props);
    return (
        <Field
            component={RadioDropdown}
            size="small"
            variant="outlined"
            value={field.value}
            autoComplete="off"
            error={meta.touched && Boolean(meta.error)}
            helperText={
                meta.touched && meta.error ? (
                    meta.error
                ) : (
                    <Typography component={"span"} ml={- 1
                    } variant="caption1" >
                        {props.initialhelper}
                    </Typography>
                )
            }
            children={
                props.options.map((option) => (
                    <RadioDropdownMenuItem key={option.value} value={option.value} selected={field.value === option.value}>
                        {option.label}
                    </RadioDropdownMenuItem>
                ))
            }
            {...field}
            {...rest}
        />
    );
}