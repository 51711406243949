import { Box, Typography, useTheme } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function SidebarMenuItem({ routeDetails }) {
  let location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <ListItemButton
      disableRipple
      disableTouchRipple
      onClick={() => {
        navigate(`${routeDetails.path}`);
      }}
      sx={{
        transition: "ease-in",
        backgroundColor: (theme) => (location.pathname.match(routeDetails.path) ? "#1e2331" : "transparent"),
        background: location.pathname.match(routeDetails.path) ? theme.sidebarMenu.itemActiveBackground : theme.sidebarMenu.itemBackground,
        border: location.pathname.match(routeDetails.path) ? `2px solid ${theme.sidebarMenu.itemActiveBorderColor}` : "initial",
        borderRight: "2px solid transparent",
        justifyContent: "center",
        px: 0.5,
        my: 1,
        width: "100%",
        display: "flex",
        position: "relative",
        gap: 1,
        flexDirection: "column",
        alignItems: "center",
        color: (theme) => (location.pathname.match(routeDetails.path) ? theme.palette.primary.main : theme.palette.text.main),
        borderRadius: "32px",
        aspectRatio: "1",
        "&:hover": {
          backgroundColor: (theme) => (location.pathname.match(routeDetails.path) ? "initial" : "transparent"),
        },
        "&::before": {
          content: "''",
          position: "absolute",
          top: "-27px",
          right: "5px",
          height: "29px",
          width: "24px",
          borderRadius: "50%",
          background: "transparent",
          borderRight: `3px solid ${theme.sidebarMenu.itemActiveTopCurveBorderColor}`,
          transform: "rotate(35deg)",
          boxShadow: (theme) => `10px 10px 0 ${theme.sidebarMenu.itemActiveTopCurveBoxShadow}`,
          display: location.pathname.match(routeDetails.path) ? "block" : "none",
        },
        "&::after": {
          content: "''",
          position: "absolute",
          bottom: "-27px",
          right: "5px",
          height: "29px",
          width: "24px",
          borderRadius: "50%",
          background: "transparent",
          borderTop: `3px solid ${theme.sidebarMenu.itemActiveBottomBorderColor}`,
          transform: "rotate(35deg)",
          boxShadow: (theme) => `0px -8px 0 ${theme.sidebarMenu.itemActiveBottomBoxShadow}`,
          display: location.pathname.match(routeDetails.path) ? "block" : "none",
        },
        marginLeft: (theme) => (location.pathname.match(routeDetails.path) ? theme.spacing(1.1) : "none"),
      }}
    >
      <ListItemIcon
        sx={{
          marginLeft: (theme) => (location.pathname.match(routeDetails.path) ? `-${theme.spacing(2)}` : "none"),
          minWidth: 0,
          justifyContent: "center",
          color: location.pathname.match(routeDetails.path) ? theme.palette.primary.main : theme.sidebarMenu.itemIconColor,
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
      >
        {routeDetails.icon}
      </ListItemIcon>
      <Typography
        sx={{
          marginLeft: (theme) => (location.pathname.match(routeDetails.path) ? `-${theme.spacing(2)}` : "none"),
        }}
        variant="caption"
        color={location.pathname.match(routeDetails.path) ? (theme) => theme.palette.primary.main : (theme) => theme.sidebarMenu.itemTextColor}
      >
        {routeDetails.text}
      </Typography>
      {location.pathname.match(routeDetails.path) && (
        <Box
          sx={{
            width: "0.5rem",
            height: "100%",
            background: theme.sidebarMenu.itemActiveFakeBorderColor,
            position: "absolute",
            right: "-1px",
          }}
        ></Box>
      )}
    </ListItemButton>
  );
}
