import axiosInstance from "./index";

const buildFormData = (formData, obj, parentKey = "") => {
  if (Array.isArray(obj)) {
    obj.forEach((element) => {
      buildFormData(formData, element, parentKey);
    });
  } else if (typeof obj === "object" && !(obj instanceof File)) {
    Object.keys(obj).forEach((key) => {
      buildFormData(formData, obj[key], parentKey ? `${parentKey}.${key}` : key);
    });
  } else {
    if (obj == null) {
      return;
    }

    const value = typeof obj === "number" || typeof obj === "boolean" ? obj.toString() : obj;
    formData.append(parentKey, value);
  }
};

const objectToFormData = (obj) => {
  const formData = new FormData();
  buildFormData(formData, obj);
  return formData;
};

export const postAPI = (url, data, config, asFormData) => {
  let requestData = {};
  if (asFormData) {
    requestData = objectToFormData(data);
  } else {
    requestData = data || {};
  }
  return axiosInstance.post(`${url}`, requestData, config);
};

export const getAPI = (url, ...rest) => {
  return axiosInstance.get(`${url}`, ...rest);
};

export const deleteAPI = (url, ...rest) => {
  return axiosInstance.delete(`${url}`, ...rest);
};

export const patchAPI = (url, data, config, asFormData) => {
  let requestData = {};
  if (asFormData) {
    requestData = objectToFormData(data);
  } else {
    requestData = data || {};
  }
  return axiosInstance.patch(`${url}`, requestData, config);
};
