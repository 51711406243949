import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Select } from "@mui/material";
import React from "react";

import BaseInputDropdown from "./BaseInputDropdown";

export default function RadioDropdown({ children, ...props }) {
  return (
    <Select
      inputProps={{
        MenuProps: {
          PaperProps: {
            sx: {
              border: "1px solid #ffffff00",
              marginTop: "8px",
            },
          },
          MenuListProps: {
            sx: {
              px: 1.3,
              background: (theme) => theme.palette.background.default,
            },
          },
        },
      }}
      size="medium"
      IconComponent={KeyboardArrowDownIcon}
      input={<BaseInputDropdown />}
      sx={(theme) => ({
        "& .MuiSelect-select": {
          borderRadius: "4px",
          pl: "10px",
          color: theme.palette.text.titleLabel,
          fontSize: "16px",
          fontWeight: "600",
          background: theme.dashboardContainer.customRadioDropdown,
          boxShadow: theme.dashboardContainer.customRadioDropdownBoxShadow
        },
      })}
      {...props}
    >
      {children}
    </Select>
  );
}
