import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAPI, postAPI } from "../../axios/utils";
import { BACKEND_SERVICE_BASE_URL, GOOGLE_MAP_REACT_API_KEY } from "../../utils/constants";

export const getLocationAddress = createAsyncThunk("coreSlice/getLocationAddress", async (data) => {
  const { lat, lng } = data;
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_MAP_REACT_API_KEY}`;
  return getAPI(url);
});

export const getGeneralInfo = createAsyncThunk("coreSlice/getGeneralInfo", async (data) => {
  const { lat, lng } = data;
  const url = `${BACKEND_SERVICE_BASE_URL}/api/general-info?lat=${lat}&lng=${lng}`;
  return getAPI(url);
});

export const getAccidentScore = createAsyncThunk("coreSlice/getAccidentScore", async (data) => {
  const params = new URLSearchParams(data);
  const paramString = params.toString();
  const url = `${BACKEND_SERVICE_BASE_URL}/api/accident-score?${paramString}`;
  return getAPI(url);
});

export const saveInferenceScoreFeedback = createAsyncThunk("coreSlice/saveInferenceScoreFeedback", async (data) => {
  return postAPI(`${BACKEND_SERVICE_BASE_URL}/api/labelled-records/`, data, {}, false);
});

export const sendFeedBack = createAsyncThunk("coreSlice/sendFeedBack", async (data) => {
  return postAPI(`${BACKEND_SERVICE_BASE_URL}/api/user-feedback/`, data, {}, false);
});

export const getFeedbackPoints = createAsyncThunk("coreSlice/getFeedbackPoints", async () => {
  return getAPI(`${BACKEND_SERVICE_BASE_URL}/api/user-feedback/`);
});

export const getHotspotPoints = createAsyncThunk("coreSlice/getHotspotPoints", async () => {
  return getAPI(`${BACKEND_SERVICE_BASE_URL}/api/labelled-records?type=Hotspot`);
});
