import { GoogleMap, LoadScript, Rectangle } from "@react-google-maps/api";
import React, { useState } from "react";
import { GOOGLE_MAP_REACT_API_KEY } from "../../../utils/constants";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const libraries = ["places", "drawing"];

const MyMapComponent = ({ }) => {

  const DEFAULT_CENTER = {
    lat: 0,
    lng: 0,
  };

  const [map, setMap] = useState(null);
  const [coOrdsData, setCoOrdsData] = useState(null);
  const [center, setCenter] = React.useState(DEFAULT_CENTER);
  const [rectangle, setRectangle] = useState(null);
  const [drawingMode, setDrawingMode] = useState(null);
  const [rectangleCoords, setRectangleCoords] = useState(null);

  return (
    <LoadScript googleMapsApiKey={GOOGLE_MAP_REACT_API_KEY} libraries={libraries}>
      <GoogleMap
        onLoad={(map) => {
          setMap(map);
        }}
        mapContainerStyle={containerStyle}
        center={center}
        zoom={16}
      >

        <Rectangle
          bounds={{
            "south": 18.582088349244547,
            "west": 73.72575688839062,
            "north": 18.582983264759818,
            "east": 73.72925448894604
          }}
          editable={false}
          draggable={false}
        />

      </GoogleMap>
    </LoadScript>
  );
};

export default MyMapComponent;