import { Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import * as React from "react";
import { useDispatch } from "react-redux";
import { dashboardActions } from "../../../store/dashboard/dashboardSlice";

export default function TrafficDensitySelector() {
  const dispatch = useDispatch();

  const onValueChange = (e) => {
    dispatch(dashboardActions.updateCustomTrafficDensity(e.target.value));
  };

  return (
    <FormControl>
      <Typography variant="caption" fontWeight="bold">
        Traffic Density:
      </Typography>
      <RadioGroup row name="row-radio-buttons-group" onChange={onValueChange}>
        <FormControlLabel value="default" control={<Radio size="small" />} label="Default" />
        <FormControlLabel value="low" control={<Radio size="small" />} label="Low" />
        <FormControlLabel value="medium" control={<Radio size="small" />} label="Medium" />
        <FormControlLabel value="traffic_jam" control={<Radio size="small" />} label="High" />
      </RadioGroup>
    </FormControl>
  );
}
