import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import React from "react";

import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import SideBar from "./components/Sidebar";

export default function DashboardBaseLayout({ children }) {
  const theme = useTheme();
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate(routes.home);
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <SideBar />
      <Box
        component="main"
        sx={{
          maxHeight: "96vh",
          overflowY: "auto",
          my: 1.5,
          mr: 1.5,
          p: 2,
          pb: "4rem",
          flexGrow: 1,
          borderRadius: "29px 29px 29px 29px",
          overflow: "hidden",
          background: theme.dashboardContainer.backround,
          boxShadow: theme.dashboardContainer.boxShadow,
          border: `2px solid ${theme.dashboardContainer.borderColor}`,
          borderLeft: "none",
          position: "relative",
          [theme.breakpoints.down("md")]: {
            background: "none",
            boxShadow: "none",
            border: "none",
            maxWidth: "100%",
            p: 0,
            pb: "4rem",
            marginTop: "70px",
            marginLeft: "10px",
          },
        }}
      >
        <Container maxWidth={false} disableGutters sx={{ margin: "0", height: "100%" }}>
          {children}
        </Container>
      </Box>
    </Box>
  );
}
