import { Box, useTheme } from "@mui/material";
import { APIProvider, ControlPosition, Map, MapControl, Marker, useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import React from "react";
import Combobox from "react-widgets/Combobox";
import { GOOGLE_MAP_REACT_API_KEY } from "../../../utils/constants";

import { computeDestinationPoint, getDistance } from "geolib";
import polyline from "polyline";
import { useDispatch, useSelector } from "react-redux";
import "react-widgets/styles.css";
import { coreActions } from "../../../store/core/coreSlice";
import { getLocationAddress } from "../../../store/core/coreThunk";
import { rangeArea } from "../../../utils/helper";
// function createMapOptions(maps) {
//   return {
//     zoomControlOptions: {
//       position: maps.ControlPosition.RIGHT_BOTTOM,
//       style: maps.ZoomControlStyle.SMALL,
//     },
//     zoomControl: true,
//     fullscreenControl: true,
//     streetViewControl: true,
//     mapTypeControl: true,
//   };
// }

const getBearing = (start, end) => {
  const startLat = (Math.PI / 180) * start.latitude;
  const startLng = (Math.PI / 180) * start.longitude;
  const endLat = (Math.PI / 180) * end.latitude;
  const endLng = (Math.PI / 180) * end.longitude;
  const dLng = endLng - startLng;
  const x = Math.sin(dLng) * Math.cos(endLat);
  const y = Math.cos(startLat) * Math.sin(endLat) - Math.sin(startLat) * Math.cos(endLat) * Math.cos(dLng);
  const bearing = Math.atan2(x, y);
  return (bearing * 180) / Math.PI;
};

export default function GoogleMap({ vehicleLat, vehicleLng, setDestinationPosition, setIsAlert }) {
  const map = useMap();
  const dispatch = useDispatch();
  const { vehicleLocation, feedbackPoints, hostpostPoints } = useSelector((state) => state.core);
  const DEFAULT_CENTER =
    vehicleLat && vehicleLng
      ? { lat: vehicleLat, lng: vehicleLng }
      : {
        lat: 0,
        lng: 0,
      };
  const [center, setCenter] = React.useState(DEFAULT_CENTER);
  const [originPlace, setOriginPlace] = React.useState(null);
  const [selectedPlace, setSelectedPlace] = React.useState(null);

  React.useEffect(() => {
    if (selectedPlace) {
      setDestinationPosition({ dest_lat: selectedPlace.geometry.location.lat(), dest_lng: selectedPlace.geometry.location.lng() });
    }
  }, [selectedPlace]);

  React.useEffect(() => {
    setCenter({ lat: vehicleLat, lng: vehicleLng });
  }, [vehicleLat, vehicleLng]);

  React.useEffect(() => {
    if (vehicleLat && vehicleLng) {
      dispatch(getLocationAddress({ lat: vehicleLat, lng: vehicleLng })).then((res) => {
        if (res.payload?.status === 200) {
          dispatch(coreActions.updateVehicleLocation(res.payload.data.results[0]));
          // if (!originPlace) {
          setOriginPlace(res.payload.data.results[0]);
          // }
        }
      });
    }
  }, [vehicleLat, vehicleLng]);

  return (
    <APIProvider apiKey={GOOGLE_MAP_REACT_API_KEY}>
      <Map defaultCenter={center} defaultZoom={16}>
        {feedbackPoints.length > 0 &&
          feedbackPoints.map((point) => {
            return (
              <Marker
                position={{ lat: point.latitude, lng: point.longitude }}
                icon={{
                  url: "https://img.icons8.com/office/32/high-importance.png",
                }}
              />
            );
          })}
        {hostpostPoints.length > 0 &&
          hostpostPoints.map((point) => {
            return (
              <Marker
                position={{ lat: point.latitude, lng: point.longitude }}
                icon={{
                  url: "https://img.icons8.com/office/32/high-risk.png",
                }}
              />
            );
          })}
        {vehicleLat && vehicleLng && (
          <Marker
            position={{ lat: vehicleLat, lng: vehicleLng }}
            icon={{
              url: "https://img.icons8.com/fluency/48/car-top-view.png",
            }}
          />
        )}
        {selectedPlace && "geometry" in selectedPlace && (
          <Marker
            position={{ lat: selectedPlace.geometry.location.lat(), lng: selectedPlace.geometry.location.lng() }}
            icon={{
              url: "https://img.icons8.com/ultraviolet/38/marker.png",
            }}
          />
        )}
        <CustomMapControl controlPosition={ControlPosition.TOP} onPlaceSelect={setSelectedPlace} />
        <MapHandler place={selectedPlace} />
        {originPlace && "formatted_address" in originPlace && selectedPlace && "formatted_address" in selectedPlace && (
          <Directions originPlace={originPlace?.formatted_address} destinationPlace={selectedPlace?.formatted_address} setIsAlert={setIsAlert} />
        )}
      </Map>
    </APIProvider>
  );
}

const MapHandler = React.memo(({ place }) => {
  const map = useMap();

  React.useEffect(() => {
    if (!map || !place) return;

    if (place.geometry?.viewport) {
      map.fitBounds(place.geometry?.viewport);
    }
  }, [map, place]);

  return null;
});

const CustomMapControl = ({ controlPosition, onPlaceSelect }) => {
  return (
    <MapControl position={controlPosition}>
      <Box className="autocomplete-control">
        {/* {id === "classic" && <PlaceAutocompleteClassic onPlaceSelect={onPlaceSelect} />}

        {id === "custom" && <AutocompleteCustom onPlaceSelect={onPlaceSelect} />} */}

        <AutocompleteCustomHybrid onPlaceSelect={onPlaceSelect} />
      </Box>
    </MapControl>
  );
};

// This uses the Combobox from "react-widgets" (https://jquense.github.io/react-widgets/docs/Combobox)
const AutocompleteCustomHybrid = ({ onPlaceSelect }) => {
  const map = useMap();
  const theme = useTheme();
  const places = useMapsLibrary("places");

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompleteSessionToken
  const [sessionToken, setSessionToken] = React.useState();

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service
  const [autocompleteService, setAutocompleteService] = React.useState(null);

  // https://developers.google.com/maps/documentation/javascript/reference/places-service
  const [placesService, setPlacesService] = React.useState(null);

  const [predictionResults, setPredictionResults] = React.useState([]);

  const [inputValue, setInputValue] = React.useState("");

  const [fetchingData, setFetchingData] = React.useState(false);

  React.useEffect(() => {
    if (!places || !map) return;

    setAutocompleteService(new places.AutocompleteService());
    setPlacesService(new places.PlacesService(map));
    setSessionToken(new places.AutocompleteSessionToken());

    return () => setAutocompleteService(null);
  }, [map, places]);

  const fetchPredictions = React.useCallback(
    async (inputValue) => {
      if (!autocompleteService || !inputValue) {
        return;
      }

      setFetchingData(true);

      const request = { input: inputValue, sessionToken };
      const response = await autocompleteService.getPlacePredictions(request);

      setPredictionResults(response.predictions);
      setFetchingData(false);
    },
    [autocompleteService, sessionToken],
  );

  const onInputChange = React.useCallback(
    (value) => {
      if (typeof value === "string") {
        setInputValue(value);
        fetchPredictions(value);
      }
    },
    [fetchPredictions],
  );

  const onSelect = React.useCallback(
    (prediction) => {
      if (!places || typeof prediction === "string") return;

      setFetchingData(true);

      const detailRequestOptions = {
        placeId: prediction.place_id,
        fields: ["geometry", "name", "formatted_address"],
        sessionToken,
      };

      const detailsRequestCallback = (placeDetails) => {
        onPlaceSelect(placeDetails);
        setInputValue(placeDetails?.formatted_address ?? "");
        setSessionToken(new places.AutocompleteSessionToken());

        setFetchingData(false);
      };

      placesService?.getDetails(detailRequestOptions, detailsRequestCallback);
    },
    [onPlaceSelect, places, placesService, sessionToken],
  );

  return (
    <Box className="autocomplete-container" sx={{ mt: 2, minWidth: "16rem" }}>
      <Combobox
        placeholder="Search for a place"
        data={predictionResults}
        dataKey="place_id"
        textField="description"
        value={inputValue}
        onChange={onInputChange}
        onSelect={onSelect}
        busy={fetchingData}
        // Since the Autocomplete Service API already returns filtered results
        // always want to display them all.
        filter={() => true}
        focusFirstItem={true}
        hideEmptyPopup
        hideCaret
        style={{
          background: theme.palette.background.paper,
        }}
      />
    </Box>
  );
};

function Directions({ originPlace, destinationPlace, setIsAlert }) {
  const map = useMap();
  const dispatch = useDispatch();
  const routesLibrary = useMapsLibrary("routes");
  const [directionsService, setDirectionsService] = React.useState();
  const [directionsRenderer, setDirectionsRenderer] = React.useState();
  const [routes, setRoutes] = React.useState([]);
  const [routeIndex, setRouteIndex] = React.useState(0);
  const selected = routes[routeIndex];
  const leg = selected?.legs[0];
  const { feedbackPoints, anticipatedVehicleLocation, vehicleLocation, currentPathPolyline } = useSelector((state) => state.core);
  const [feedbackPointsInPath, setFeedbackPointsInPath] = React.useState([]);
  const [rangePathPoints, setRangePathPoints] = React.useState([]);
  const [polylinePoints, setPolylinePoints] = React.useState([]);

  // const currentLocation = vehicleLocation?.geometry?.location;
  const currentLocation = anticipatedVehicleLocation;
  // console.log("currentLoca", currentLocation);
  React.useEffect(() => {
    const filteredFeedbacks = [];
    feedbackPoints.forEach((feedbackPoint, index) => {
      rangePathPoints.forEach((area) => {
        if (
          area.lat_upper >= feedbackPoint.latitude &&
          area.lat_lower <= feedbackPoint.latitude &&
          area.lng_upper >= feedbackPoint.longitude &&
          area.lng_lower <= feedbackPoint.longitude
        ) {
          // area.feedback= feedbackPoint.feedback
          const areaFeedback = rangeArea({ latitude: feedbackPoint.latitude, longitude: feedbackPoint.longitude }, 100);
          areaFeedback.index = index;
          filteredFeedbacks.push(areaFeedback);
        }
      });
    });

    // setFeedbackPointsInPath(filteredFeedbacks);
    // check if the anticipate vehicle location is in any filteredFeedbackPoints
    const detectedAlerts = [];
    filteredFeedbacks.forEach((area) => {
      if (
        area.lat_upper >= currentLocation.lat &&
        area.lat_lower <= currentLocation.lat &&
        area.lng_upper >= currentLocation.lng &&
        area.lng_lower <= currentLocation.lng
      ) {
        detectedAlerts.push(area.index);
      }
    });
    setIsAlert([...new Set(detectedAlerts)]);
  }, [currentLocation, feedbackPoints, rangePathPoints]);

  // Initialize directions service and renderer
  React.useEffect(() => {
    if (!routesLibrary || !map) return;
    setDirectionsService(new routesLibrary.DirectionsService());
    setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map, suppressMarkers: true }));
  }, [routesLibrary, map, originPlace, destinationPlace]);

  // Use directions service
  React.useEffect(() => {
    if (!directionsService || !directionsRenderer) return;

    directionsService
      .route({
        origin: originPlace, //"100 Front St, Toronto ON",
        destination: destinationPlace, //"500 College St, Toronto ON",
        // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
        provideRouteAlternatives: false,
      })
      .then((response) => {
        directionsRenderer.setDirections(response);
        setRoutes(response.routes);
        const points = polyline.decode(response.routes[0].overview_polyline);
        const coords = points.map((point) => ({ latitude: point[0], longitude: point[1] }));
        const rangeOfPoints = [];
        coords.forEach((coord) => {
          rangeOfPoints.push(rangeArea(coord, 100));
        });
        setRangePathPoints(rangeOfPoints);

        // console.log("rangeOfPoints :", rangeOfPoints);
        // check which feedback points are in the path

        let totalDistance = 0;
        let segmentDistance = 0;
        for (let i = 0; i < coords.length - 1; i++) {
          segmentDistance = getDistance(coords[i], coords[i + 1]);
          totalDistance += segmentDistance;
          if (totalDistance >= 500) {
            const overshoot = totalDistance - 500;
            const bearing = getBearing(coords[i], coords[i + 1]);
            const finalPoint = computeDestinationPoint(coords[i], segmentDistance - overshoot, bearing);
            // console.log("finalPoint", finalPoint);
            dispatch(coreActions.updateAnticipatedVehicleLocation({ lat: finalPoint.latitude, lng: finalPoint.longitude }));
            break;
          }
        }
      });

    return () => directionsRenderer.setMap(null);
  }, [directionsService, directionsRenderer, originPlace, destinationPlace]);

  // Update direction route
  React.useEffect(() => {
    if (!directionsRenderer) return;
    directionsRenderer.setRouteIndex(routeIndex);
  }, [routeIndex, directionsRenderer, destinationPlace, originPlace]);

  if (!leg) return null;

  return (
    <div className="directions">
      {/* <h2>{selected.summary}</h2> */}
      {/* <p>
        {leg.start_address.split(",")[0]} to {leg.end_address.split(",")[0]}
      </p> */}
      <p>Distance: {leg.distance?.text}</p>
      <p>Duration: {leg.duration?.text}</p>
      <Marker
        position={{ lat: anticipatedVehicleLocation.lat, lng: anticipatedVehicleLocation.lng }}
        icon={{
          url: "https://img.icons8.com/ultraviolet/38/marker.png",
        }}
      />
      {/* <h2>Other Routes</h2>
      <ul>
        {routes.map((route, index) => (
          <li key={route.summary}>
            <button onClick={() => setRouteIndex(index)}>{route.summary}</button>
          </li>
        ))}
      </ul> */}
    </div>
  );
}
