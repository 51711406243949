import { TextField, styled } from "@mui/material";

const CustomTextField = styled(TextField)(({ theme, customWidth = "", customMargin = "", type }) => ({
  width: customWidth !== "" ? customWidth : "100%",
  marginLeft: customMargin !== "" ? customMargin : "0px",
  marginTop: "20px",
  boxShadow: type === "DropDownInput" ? theme.dashboardContainer.customButtonBoxShadow : "none",
  borderRadius: type === "DropDownInput" ? "4px" : "0px",
  "& :-webkit-autofill": {
    transitionDelay: "9999s",
  },
  "& .MuiInputBase-input::placeholder": {
    color: theme.palette.text.defaultInputField,
  },
  "& .Mui-error .MuiInputBase-input": {
    color: theme.palette.text.errorInputField,
  },
  "& .MuiInputBase-input:focus::placeholder": {
    color: "transparent",
  },
  "& .MuiInputBase-root.Mui-focused": {
    color: type === "password" ? theme.palette.primary.main : theme.palette.text.activeInputField,
  },
  "&:active:hover .MuiInputBase-root": {
    color: type === "password" ? theme.palette.primary.main : theme.palette.text.activeInputField,
  },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "#BDC8D3",
    opacity: 0.12,
  },
  "& .MuiInputAdornment-root ": {
    marginRight: "5px",
  },
  "& .MuiInputBase-adornedEnd": {
    boxShadow: type !== "DropDownInput" ? theme.dashboardContainer.insetBoxShadow : "none",
    backgroundColor: theme.palette.background.default,
  },
  "& .MuiInputBase-adornedEnd .MuiSvgIcon-root": {
    width: "20px",
    height: "20px",
    color: theme.palette.text.defaultInputField,
  },
  "& .MuiInputBase-adornedEnd.Mui-focused .MuiSvgIcon-root": {
    width: "20px",
    height: "20px",
    color: theme.palette.text.activeInputField,
  },
  "& .MuiInputBase-adornedEnd.Mui-error .MuiSvgIcon-root": {
    width: "20px",
    height: "20px",
    color: theme.palette.text.activeInputField,
  },
  "& .MuiInputBase-adornedStart": {
    boxShadow: type !== "DropDownInput" ? theme.dashboardContainer.customInputBoxShadow : "none",
    backgroundColor: theme.palette.background.default,
  },
  "& .MuiInputBase-adornedStart .MuiSvgIcon-root": {
    width: "20px",
    height: "20px",
    color: theme.palette.text.defaultStartIcon,
  },
  "& .MuiInputBase-adornedStart.Mui-focused .MuiSvgIcon-root": {
    width: "20px",
    height: "20px",
    color: theme.palette.primary.main,
  },
  "& .MuiButtonBase-root-MuiIconButton-root": {
    backgroundColor: theme.palette.background.default,
  },
  "&:active .MuiButtonBase-root-MuiIconButton-root": {
    backgroundColor: theme.palette.background.default,
  },
  "& .MuiInputBase-input": {
    background: theme.palette.background.default,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: "20px",
  },
  "& .MuiInputBase-input.Mui-error": {
    color: theme.palette.error.main,
  },
  "& .MuiInputBase-input.MuiInput-input.Mui-disabled": {
    WebkitTextFillColor: `${theme.palette.text.titleLabel}`,
  },
}));

const CustomInputField = ({ field, form, ...props }) => {
  return <CustomTextField {...field} {...props} />;
};

export default CustomInputField;
