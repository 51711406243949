import { Button } from "@mui/material";
import React from "react";

export default function CustomButton({ children, sx, ...props }) {
  return (
    <Button
      sx={(theme) => ({
        color: theme.palette.primary.main,
        background: theme.dashboardContainer.customButtonBackgroundColor,
        boxShadow: theme.dashboardContainer.customButtonBoxShadow,
        borderRadius: "4px",
        "&:hover": {
          backgroundColor: theme.palette.background.secondaryGradient,
          boxShadow: theme.dashboardContainer.customButtonBoxShadow,
          border: "none",
        },
        "&:active": {
          backgroundColor: theme.palette.background.darkGradient,
          boxShadow: "0px 0px 1px rgba(48, 62, 90, 0.55), 0px 0px 2px #12161D",
        },
        "&.Mui-disabled": {
          background: theme.palette.action.disabled,
          color: theme.palette.background.dark,
          border: "none",
        },
        ...sx,
      })}
      {...props}
      
    >
      {children}
    </Button>
  );
}
