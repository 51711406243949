import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import { DrawingManager, GoogleMap, LoadScript, Marker, Polyline } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { GOOGLE_MAP_REACT_API_KEY } from "../../../utils/constants";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const libraries = ["places", "drawing"];

const MyMapComponent = ({ coOrdinates, fromCoords, toCoords, setShowForm, handleInputChange }) => {

  const DEFAULT_CENTER = {
    lat: 0,
    lng: 0,
  };

  const [map, setMap] = useState(null);
  const [coOrdsData, setCoOrdsData] = useState(null);
  const [center, setCenter] = React.useState(DEFAULT_CENTER);
  const [rectangle, setRectangle] = useState(null);
  const [drawingMode, setDrawingMode] = useState(null);
  const [rectangleCoords, setRectangleCoords] = useState(null);
  const [insideCoOrdsData, setInsideCoOrdsData] = useState([]);

  useEffect(() => {
    if (coOrdinates) {
      let data = coOrdinates;

      let locations = [];
      let location_segments = [];

      let segments = [];
      let current_segment = data[1]?.[2] || "NORMAL";
      for (let i = 1; i < data.length; i++) {
        locations.push({ lat: data[i - 1][0], lng: data[i - 1][1] });
        if (current_segment == data[i][2]) {
          segments.push({ lat: data[i - 1][0], lng: data[i - 1][1] });
        } else {
          segments.push({ lat: data[i][0], lng: data[i][1] });
          location_segments.push({ points: segments, color: "#00FF00" });
          current_segment = data[i][2];
          segments = [{ lat: data[i][0], lng: data[i][1] }];
        }
      }
      location_segments.push({ points: segments, color: "#00FF00" });
      if (data[data.length - 1]?.[0] && data[data.length - 1]?.[1] && data[data.length - 2]?.[0] && data[data.length - 2]?.[1]) {
        location_segments.push({ points: [{ lat: data[data.length - 1]?.[0], lng: data[data.length - 1]?.[1] }, { lat: data[data.length - 2]?.[0], lng: data[data.length - 2]?.[1] }], color: "#00FF00" });
      }

      let new_segments = [{ points: [], color: "#00FF00" }, { points: [], color: "#FF6347" }];

      location_segments.forEach((segment) => {
        new_segments[0].points = new_segments[0].points.concat(segment.points);
      });

      location_segments = new_segments;

      setCoOrdsData(location_segments);
    }

    if (fromCoords && toCoords && map) {
      setCenter({ lat: (fromCoords[0] + toCoords[0]) / 2, lng: (fromCoords[1] + toCoords[1]) / 2 });
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend({ lat: fromCoords[0], lng: fromCoords[1] });
      bounds.extend({ lat: toCoords[0], lng: toCoords[1] });
      map.fitBounds(bounds);
    }
  }, [coOrdinates, fromCoords, toCoords]);

  const isCoordinateInsideRectangle = (coord, bounds) => {
    return (
      coord.lat >= bounds.getSouthWest().lat() &&
      coord.lat <= bounds.getNorthEast().lat() &&
      coord.lng >= bounds.getSouthWest().lng() &&
      coord.lng <= bounds.getNorthEast().lng()
    );
  };

  const handleOverlayComplete = (e) => {
    if (rectangle) {
      rectangle.setMap(null);
    }
    const newRectangle = e.overlay;
    setRectangle(newRectangle);
    setDrawingMode(null);

    const bounds = newRectangle.getBounds();
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    setRectangleCoords({
      northEast: { lat: ne.lat(), lng: ne.lng() },
      southWest: { lat: sw.lat(), lng: sw.lng() },
    });

    newRectangle.addListener('bounds_changed', () => {
      const updatedBounds = newRectangle.getBounds();
      const updatedNe = updatedBounds.getNorthEast();
      const updatedSw = updatedBounds.getSouthWest();
      setRectangleCoords({
        northEast: { lat: updatedNe.lat(), lng: updatedNe.lng() },
        southWest: { lat: updatedSw.lat(), lng: updatedSw.lng() },
      });
      updateCoordsDataWithRectangle(updatedBounds);
    });

    newRectangle.addListener('click', () => {

    });

    updateCoordsDataWithRectangle(bounds);

  };

  useEffect(() => {
    handleInputChange({
      fieldName: "insideCoOrdsData",
      value: insideCoOrdsData,
    });
  }, [insideCoOrdsData]);

  const updateCoordsDataWithRectangle = (bounds) => {
    if (coOrdsData) {
      const oldPoints = coOrdsData.map((segment) => segment.points).flat();
      const updatedCoOrdsData = [];
      oldPoints.map((point) => {
        const isInside = isCoordinateInsideRectangle(point, bounds);
        if (isInside) {
          setInsideCoOrdsData(
            (prev) => [...prev, point]
          );
          if (updatedCoOrdsData[updatedCoOrdsData.length - 1]?.color === "#FF6347") {
            updatedCoOrdsData[updatedCoOrdsData.length - 1].points.push(point);
          } else {
            updatedCoOrdsData.push({ points: [point], color: "#FF6347" });
          }
        }
        else {
          if (updatedCoOrdsData[updatedCoOrdsData.length - 1]?.color === "#00FF00") {
            updatedCoOrdsData[updatedCoOrdsData.length - 1].points.push(point);
          } else {
            updatedCoOrdsData.push({ points: [point], color: "#00FF00" });
          }
        }
      });
      setCoOrdsData(updatedCoOrdsData);
    }
  }

  useEffect(() => {
    if (rectangleCoords) {
      handleInputChange({
        fieldName: "rectangleCoords",
        value: rectangleCoords,
      });
      setShowForm(true);
    }
  }, [rectangleCoords]);

  return (
    <LoadScript googleMapsApiKey={GOOGLE_MAP_REACT_API_KEY} libraries={libraries}>
      <GoogleMap
        onLoad={(map) => {
          setMap(map);
        }}
        mapContainerStyle={containerStyle}
        center={center}
        zoom={16}
      >
        <Box
          className="autocomplete-control"
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            style={{
              position: "absolute",
              top: "60px",
              right: "10px",
              zIndex: "1",
              padding: "8px",
              backgroundColor: "#fff",
              borderRadius: "5px",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              cursor: "pointer",
              color: "#333333",
            }}
            onClick={() => setDrawingMode(drawingMode ? null : "rectangle")}>
            {drawingMode ? "Release To Stop Marking" : "Mark"}
          </Button>
        </Box>

        {drawingMode && (
          <DrawingManager
            drawingMode={drawingMode}
            onOverlayComplete={handleOverlayComplete}
            options={{
              drawingControl: false,
              rectangleOptions: {
                fillColor: "#FF0000",
                fillOpacity: 0.1,
                strokeWeight: 2,
                clickable: true,
                editable: true,
                draggable: true,
              },
            }}
          />
        )}
        {/* <Rectangle
          bounds={{
            "south": 18.582088349244547,
            "west": 73.72575688839062,
            "north": 18.582983264759818,
            "east": 73.72925448894604
          }}
          editable={false}
          draggable={false}
        /> */}
        {coOrdsData?.map((segment, index) => (
          <Polyline key={index} path={segment.points} options={{ strokeColor: segment.color, strokeOpacity: 1.0, strokeWeight: 4 }} />
        ))}

        {coOrdinates && fromCoords && <Marker
          position={{ lat: fromCoords[0], lng: fromCoords[1] }}
          style={{ position: "absolute", transform: "translate(-50%, -100%)" }}
          icon={{
            url: "https://img.icons8.com/?size=30&id=2436&format=png&color=000000",
          }}
        />}

        {coOrdinates && toCoords && <Marker
          position={{ lat: toCoords[0], lng: toCoords[1] }}
          style={{ position: "absolute", transform: "translate(-50%, -100%)" }}
          icon={{
            url: "https://img.icons8.com/?size=30&id=2436&format=png&color=000000",
          }}
        />}

      </GoogleMap>
    </LoadScript>
  );
};

export default MyMapComponent;