import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import { useTheme } from "@mui/material";

export default function NoSidebarDashboardBaseLayout({ children }) {
  const theme = useTheme();

  return (
    <Box>
      <Box
        component="main"
        sx={{
          height: "100vh",
          overflowY: "scroll",
          p: 2,
          background: theme.palette.background.gradientLight2,
        }}
      >
        <Container maxWidth={false} disableGutters sx={{ margin: "0", height: "100%" }}>
          {children}
        </Container>
      </Box>
    </Box>
  );
}
