import { BrowserRouter, Routes as CoreRoutes, Route } from "react-router-dom";

import Home from "../pages/home";
import PrivateRoute from "./PrivateRoute";
import { routesArr } from "./index";

export default function Routes() {
  return (
    <BrowserRouter>
      <CoreRoutes>
        {routesArr.map((route, index) => {
          return (
            <Route
              key={index}
              path={route[1].path}
              exact
              element={<PrivateRoute showSideDrawer={route[1].showSideDrawer}>{route[1].page}</PrivateRoute>}
            />
          );
          // return <Route key={index} path={route[1].path} exact element={route[1].page} />;
        })}
        {/* fallback route to show the 404 page */}
        <Route
          path="*"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
      </CoreRoutes>
    </BrowserRouter>
  );
}
