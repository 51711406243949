import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import ReactApexChart from "react-apexcharts";

export default function CustomRadialBar({ label, text, series }) {
  const theme = useTheme();
  const [barData, setBarData] = React.useState({
    chart: {
      height: 125,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "30%",
          background: "transparent",
        },
        track: {
          background: "#181d28",
          startAngle: 0,
          endAngle: 360,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            formatter: function (val) {
              return String(val);
            },
            color: "transparent",
            fontSize: "12px",
            show: false,
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        gradientToColors: ["#2EA3FF", "#1C5D91"],
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: "butt",
    },
  });

  return (
    <Box>
      <Box id="card" sx={{ height: "84px", marginTop: "-8px", mb: 0.5 }}>
        <Box id="chart">
          <ReactApexChart options={barData} series={[series]} type="radialBar" height={125} />
        </Box>
      </Box>
      <Typography
        variant="subtitle2"
        color={theme.palette.text.titleLabel}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "-42px",
          marginBottom: "23px",
          fontSize: "15px",
        }}
      >
        {text}
      </Typography>
      <Typography
        variant="subtitle3"
        color={theme.palette.text.titleLabel}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center", textTransform: "capitalize", mt: 1 }}
      >
        {label}
      </Typography>
    </Box>
  );
}
