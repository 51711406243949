import { useTheme } from "@emotion/react";
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import CustomDivider from "../../../components/CustomDivider";
import KnownIssuesDialog from "./KnownIssuesDialog";

export default function KnownIssues() {
  const theme = useTheme();
  const { knownIssues } = useSelector((state) => state.core);
  const [openDialog, setOpenDialog] = React.useState(false);

  const toggleDialog = () => {
    setOpenDialog((prev) => !prev);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Typography
          variant="h2"
          sx={(theme) => ({
            fontFamily: theme.typography.fontFamily,
            fontSize: "22px",
            lineHeight: "100%",
          })}
        >
          Known Issues:
        </Typography>
        <Box>
          <Button
            variant="text"
            size="medium"
            sx={{
              //   ...theme.typography.bigButton,
              justifyContent: "right",
              mr: 0,
              "&.MuiButtonBase-root:hover": {
                bgcolor: "transparent",
              },
            }}
            onClick={() => toggleDialog()}
          >
            See all
          </Button>
          <KnownIssuesDialog open={openDialog} handleToggle={toggleDialog} />
        </Box>
      </Box>
      <CustomDivider />
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
        {knownIssues.map((value, index) => {
          return (
            <Box
              key={index}
              sx={{
                px: 2,
                py: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: theme.palette.primary.tertiary,
                borderRadius: 1,
              }}
            >
              <Typography variant="body5" color={theme.palette.error.btnText}>
                {value}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
