import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { coreActions } from "../../../store/core/coreSlice";

export default function TrafficDensitySelector() {
  const dispatch = useDispatch();

  const onValueChange = (e) => {
    dispatch(coreActions.updateTrafficDensity(e.target.value));
  };

  return (
    <FormControl>
      <Typography variant="caption" fontWeight="bold">
        Traffic Density:
      </Typography>
      <RadioGroup row name="row-radio-buttons-group" onChange={onValueChange}>
        <FormControlLabel value="default" control={<Radio size="small" />} label="Default" />
        <FormControlLabel value="low" control={<Radio size="small" />} label="Low" />
        <FormControlLabel value="medium" control={<Radio size="small" />} label="Medium" />
        <FormControlLabel value="traffic_jam" control={<Radio size="small" />} label="High" />
      </RadioGroup>
    </FormControl>
  );
}
