import { useTheme } from "@mui/material";
import Divider from "@mui/material/Divider";
import React from "react";

export default function CustomDivider({ orientation = "horizontal", isVisible = true, sx, ...rest }) {
  const theme = useTheme();

  return (
    <Divider
      orientation={orientation}
      sx={{
        margin: "0 auto !important",
        border: isVisible ? theme.dashboardContainer.customDividerBorder : "none",
        boxShadow: theme.dashboardContainer.customDividerBoxShadow,
        ...sx,
      }}
      {...rest}
    />
  );
}
