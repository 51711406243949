import { createSlice } from "@reduxjs/toolkit";

// Setting dark theme by default if the app is opened first time
if (!localStorage.getItem("isDarkTheme")) localStorage.setItem("isDarkTheme", true);

const initialState = {
  isDarkTheme: localStorage.getItem("isDarkTheme") === "true" ? true : false,
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleTheme: (state) => {
      state.isDarkTheme = !state.isDarkTheme;
      localStorage.setItem("isDarkTheme", state.isDarkTheme);
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleTheme } = themeSlice.actions;
export default themeSlice;
