const commons = {
  typography: {
    h1: {
      fontWeight: 600,
      fontSize: "32px",
      lineHeight: "100%",
      textTransform: "capitalize",
      letterSpacing: 0,
      color: "#C7DDFD",
    },
    h2: {
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "110%",
      textTransform: "capitalize",
      letterSpacing: 0,
      color: "#C7DDFD",
    },
    h3: {
      fontWeight: 600,
      fontSize: "26px",
      lineHeight: "100%",
      textTransform: "capitalize",
      letterSpacing: 0,
    },
    h4: {
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "100%",
      textTransform: "capitalize",
      letterSpacing: 0,
      color: "#C7DDFD",
    },
    h5: {
      fontWeight: 300,
      fontSize: "16px",
      lineHeight: "100%",
      textTransform: "capitalize",
      letterSpacing: 0,
      color: "#C7DDFD",
    },
    h6: {
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "100%",
      letterSpacing: 0,
      color: "#C7DDFD",
    },
    //subtitles
    subtitle1: {
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "100%",
      letterSpacing: 0,
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "100%",
      letterSpacing: 0,
      color: "#C7DDFD",
    },
    subtitle3: {
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "100%",
      letterSpacing: 0,
    },
    //Body
    body1: {
      fontWeight: 400,
      fontSize: "20px",
      lineHeight: "100%",
      letterSpacing: 0,
      color: "#A0ADB8",
    },
    body2: {
      fontWeight: 300,
      fontSize: "20px",
      lineHeight: "100%",
      letterSpacing: 0,
      color: "#A0ADB8",
    },
    body3: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "100%",
      letterSpacing: 0,
    },
    body4: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "130%",
      letterSpacing: 0,
      color: "#A0ADB8",
    },
    body5: {
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "100%",
      letterSpacing: 0,
      color: "#A0ADB8",
    },
    body6: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "130%",
      letterSpacing: 0,
      color: "#A0ADB8",
    },
    //caption
    caption: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "100%",
      letterSpacing: 0,
      color: "#A0ADB8",
    },
    caption1: {
      fontWeight: 200,
      fontSize: "14px",
      lineHeight: "100%",
      textTransform: "capitalize",
      letterSpacing: 0,
      color: "#A0ADB8",
    },
    caption2: {
      fontWeight: 300,
      fontSize: "12px",
      lineHeight: "100%",
      letterSpacing: 0,
    },
    //custom defined
    link5: {
      fontWeight: 600,
      fontSize: "14px",
      letterSpacing: 0,
      color: "#2EA3FF",
    },
    //Buttons
    hugeButton: {
      fontWeight: 700,
      fontSize: "22px",
      textTransform: "capitalize",
      color: "#1D2330",
    },
    bigButton: {
      fontWeight: 600,
      fontSize: "16px",
      letterSpacing: 0,
      color: "#2EA3FF",
    },
    regularButton: {
      fontWeight: 500,
      fontSize: "14px",
      letterSpacing: 0,
      color: "#2EA3FF",
    },
    smallButton: {
      fontWeight: 500,
      fontSize: "12px",
      letterSpacing: 0,
      color: "#2EA3FF",
    },
    controllerButton: {
      fontFamily: "Inter",
      fontWeight: 900,
      fontSize: "8px",
      letterSpacing: 0,
      color: "#C7DDFD",
    },
    button: {
      textTransform: "none",
    },
    fontFamily: "League Spartan",
    fontStyle: "normal",
    titleFontWeight: 600,
    subtitleFontWeight: 200,
    fontFeatureSettings: "'liga' off",
  },
  components: {
    // Name of the component
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
  },
};

export const lightTheme = {
  ...commons,
  typography: {
    h1: {
      ...commons.typography.h1,
      color: "#1D2330",
    },
    h2: {
      ...commons.typography.h2,
      color: "#1D2330",
    },
    h3: {
      ...commons.typography.h3,
      color: "#1D2330",
    },
    h4: {
      ...commons.typography.h4,
      color: "#1D2330",
    },
    h5: {
      ...commons.typography.h5,
      color: "#1D2330",
    },
    h6: {
      ...commons.typography.h6,
      color: "#1D2330",
    },
    //subtitles
    subtitle1: {
      ...commons.typography.subtitle1,
      color: "#454B58",
    },
    subtitle2: {
      ...commons.typography.subtitle2,
      color: "#1D2330",
    },
    subtitle3: {
      ...commons.typography.subtitle3,
      color: "#454B58",
    },
    //Body
    body1: {
      ...commons.typography.body1,
      color: "#454B58",
    },
    body2: {
      ...commons.typography.body2,
      color: "#454B58",
    },
    body3: {
      ...commons.typography.body3,
      color: "#454B58",
    },
    body4: {
      ...commons.typography.body4,
      color: "#454B58",
    },
    body5: {
      ...commons.typography.body5,
      color: "#454B58",
    },
    body6: {
      ...commons.typography.body6,
      color: "#454B58",
    },
    //caption
    caption: {
      ...commons.typography.caption,
      color: "#454B58",
    },
    caption1: {
      ...commons.typography.caption1,
      color: "#454B58",
    },
    caption2: {
      ...commons.typography.caption2,
      color: "#454B58",
    },
    //custom defined
    link5: {
      ...commons.typography.link5,
      color: "#2EA3FF",
    },
    //Buttons
    hugeButton: {
      ...commons.typography.hugeButton,
      color: "#0166FF",
    },
    bigButton: {
      ...commons.typography.bigButton,
      color: "#0166FF",
    },
    regularButton: {
      ...commons.typography.regularButton,
      color: "#0166FF",
    },
    smallButton: {
      ...commons.typography.smallButton,
      color: "#0166FF",
    },
    controllerButton: {
      ...commons.typography.controllerButton,
      color: "#1D2330",
    },
    button: {
      textTransform: "none",
    },
    fontFamily: "League Spartan",
    fontStyle: "normal",
    titleFontWeight: 600,
    subtitleFontWeight: 200,
    fontFeatureSettings: "'liga' off",
  },
  sidebarMenu: {
    background: "#1e2331",
    itemIconColor: "#777D8A",
    itemTextColor: "#777D8A",
    itemActiveBackground: "linear-gradient(143.11deg, #D9D9D9 4%, #D9D9D9 95.49%)",
    itemBackground: "initial",
    itemActiveBorderColor: "#D1D1D1",
    itemActiveRightBorderColor: "transparent",
    itemActiveTopCurveBoxShadow: "#D9D9D9",
    itemActiveBottomBoxShadow: "#D9D9D9",
    itemActiveTopCurveBorderColor: "#D9D9D9",
    itemActiveBottomBorderColor: "#D9D9D9",
    itemActiveFakeBorderColor: "#D9D9D9",
    itemBorderRightColor: "#D1D1D1",
  },
  dashboardContainer: {
    firstThemeColor: "#D9D9D9",
    secondThemeColor: "#D1D1D1",
    thirdThemeColor: "#F4F4F6",
    backround: "linear-gradient(143.11deg, #D9D9D9 4%, #F4F4F6 95.49%)",
    boxShadow: "-10px -10px 20px #D9D9D9, 10px 10px 20px #F4F4F6",
    borderColor: "#D9D9D9",
    insetBoxShadow: "4px 4px 15px 0px #ACACC0B2 inset, -4px -4px 15px 0px #FFF inset",
    outsetBoxShadow: "4px 4px 11px 0px rgba(141, 141, 158, 0.45), -4px -4px 8px 0px #FFF",
    navbarCurveColorMD: "#DFDFDF",
    navbarCurveColorXS: "#F4F4F6",
    navbarCurveLeftColor: "#F4F4F6",
    navbarCurveBoxShadow: "-3.5px 0px 6px 0px #282F47",
    cardTitleColorActive: "#1D2330",
    cardTitleColor: "#777D8A",
    closeButtonColor: "linear-gradient(316deg, #F7F7F9 0%, #EBEBEE 100%)",
    closeButtonBoxShadow: "2px 2px 6px 0px rgba(141, 141, 158, 0.60), -2px -2px 8px 0px #FFF",
    customDividerBoxShadow: "0px 1px 1px 0px rgba(172, 172, 192, 0.25) inset, 0px 2px 2px #FFF",
    customDividerBorder: "1px solid #CBCBD0",
    customSwitchBoxShadow: "1px 1px 7px 0px rgba(172, 172, 192, 0.85) inset, -2px -2px 7px 0px #FFF inset",
    themeButtonBackground: "linear-gradient(316deg, #F7F7F9 0%, #EBEBEE 100%)",
    themeButtonBoxShadow: "2px 2px 6px 0px rgba(141, 141, 158, 0.60), -2px -2px 8px 0px #FFF",
    errorButtonBoxShadow: "2px 2px 6px 0px #9797A8, -3px -3px 5px 0px #FFF",
    customButtonBackgroundColor: "#EFEFF1",
    customButtonBoxShadow: "4px 4px 11px 0px #8d8d9e73, -4px -4px 8px 0px #FFFFFF",
    customButtonActiveBoxShadow: "0px 0px 1px 0px rgba(18, 22, 29, 0.70), 0px 0px 1px 0px rgba(48, 62, 90, 0.30)",
    customPendingButtonBackground: "rgba(241, 119, 66, 0.1)",
    customCardInsetBoxShadow: "4px 4px 15px 0px #ACACC0B2 inset, -4px -4px 15px 0px #FFF inset",
    shareMenuDropdownBoxShadow: "2px 2px 6px 0px rgba(141, 141, 158, 0.45), -3px -3px 6px 0px #FFF",
    primaryButtonBackgroundColor: "linear-gradient(316deg, rgba(183, 183, 183, 0.70) 0%, rgba(255, 255, 255, 0.70) 100%)",
    primaryButtonBoxShadow: "2px 2px 6px 0px #9797A8, -3px -3px 5px 0px #FFF",
    commentsFieldBackgroundColor: "#F4F4F6",
    commentsFieldBoxShadow: "2px 2px 10px 0px  #ACACC0CC inset, -4px -4px 10px 0px #FFF inset",
    customInputBoxShadow: "2px 2px 10px 0px  #ACACC0CC inset, -4px -4px 10px 0px #FFF inset",
    tableBorder: "#C8C8C8",
    customRadioDropdownBackground: "linear-gradient(90deg, #EBEBEE 0%, #E7E7EC 100%)",
    customRadioDropdownBoxShadow: "2px 2px 6px 0px rgba(141, 141, 158, 0.45), -3px -3px 6px 0px #FFF",
    areaCameraCardBackgroundColor: "#DFDFE4",
    authBackground: "linear-gradient(143.11deg, #F4F4F6 4%, #EDEDF0 95.49%)",
    authBoxShadow: "14px 14px 40px 0px #A1A1B2B2,-14px -14px 35px 0px #FFFFFF",
    unactiveStatusButtonTextColor: "#1D2330",
    verticalDividerBoxShadow: "none",
    verticalDividerColor: "#A1A1B2B2",
    storageCardBackground: "#EFEFF1",
    containerBackground:
      "radial-gradient(72.99% 100% at 50% 0%, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0) 50.52%, rgba(255, 255, 255, 0) 100%)",
  },
  playerCameraDrawer: {
    playCameraDrawerBackgroundColor: "linear-gradient(90deg, #EBEBEE 0%, #E7E7EC 100%)",
    playCameraDrawerBoxShadow: "34px 14px 55px 0px rgba(80, 89, 111, 0.34)",
    playCameraDrawerBorderColor: "rgba(58, 82, 130, 0.24)",
    selectionCardBackgroundColor: "linear-gradient(143deg, #F4F4F6 4%, #EDEDF0 95.49%)",
    selectionCardBoxShadow: "8px 8px 17px 0px rgba(142, 142, 158, 0.70), -6px -6px 10px 0px #FFF",
    playButtonBoxShadow: "3px 3px 10px 0px #9797A8, -4px -4px 7px 0px #FFF",
  },
  playerLocationDrawer: {
    playLocationDrawerBackgroundColor: "linear-gradient(90deg, #EBEBEE 0%, #E7E7EC 100%)",
    playLocationDrawerBoxShadow: "34px 14px 55px 0px rgba(80, 89, 111, 0.34)",
    playLocationDrawerBorderColor: "rgba(58, 82, 130, 0.24)",
    selectionCardBackgroundColor: "linear-gradient(143deg, #F4F4F6 4%, #EDEDF0 95.49%)",
    selectionCardBoxShadow: "8px 8px 17px 0px rgba(142, 142, 158, 0.70), -6px -6px 10px 0px #FFF",
    playButtonBoxShadow: "3px 3px 10px 0px #9797A8, -4px -4px 7px 0px #FFF",
    peopleDropdownBackgroundColor: "linear-gradient(143deg, rgba(240, 240, 243, 0.70) 4%, rgba(215, 215, 218, 0.70) 95.49%)",
    peopleDropdownBoxShadow: "4px 4px 8px 0px rgba(142, 142, 158, 0.50), -3px -3px 6px 0px #FFF",
  },
  cameraBox: {
    headBackgroundColor: "linear-gradient(0deg, #EFEFF1, #EFEFF1),linear-gradient(135deg, #D4D7E2 6.48%, #C0C1CA 100%)",
    labelColor: "#1D2330",
    boxShadowColor: "#D9D9D9",
    defaultBorder: "1px solid #D4D7E2",
    selectedBorder: "1px solid #0166FF",
    headerBackgroundColor: "#D9D9D9",
    playerControlBackgroundColor: "#F4F4F6",
    // playerControlBoxShadowColor: "none",
    seekbarBoxShadow: "2px 2px 6px 0px rgba(141, 141, 158, 0.60), -2px -2px 8px 0px #FFF",
    playerControlButtonBoxShadow: "2px 2px 6px 0px rgba(141, 141, 158, 0.60), -2px -2px 8px 0px #FFF",
    playerControlButtonColor: "linear-gradient(316deg, #F7F7F9 0%, #EBEBEE 100%)",
    playerControlBorderColor: "#D9D9D9",
    liveButtonBackgroundColor: "linear-gradient(316deg, rgba(183, 183, 183, 0.70) 0%, rgba(255, 255, 255, 0.70) 100%)",
    liveButtonBoxShadow: "1px 1px 2px 0px rgba(101, 133, 203, 0.10) inset, -1px -1px 2px 0px rgba(21, 23, 29, 0.30) inset",
    cameraControlButtonBackgroundColor: "linear-gradient(316deg, rgba(183, 183, 183, 0.70) 0%, rgba(255, 255, 255, 0.70) 100%)",
    speedsliderbgColor:
      "linear-gradient(0deg, #0166FF, #0166FF),linear-gradient(90deg, rgba(200, 200, 200, 0.3) 0%, rgba(199, 199, 199, 0.3) 53.13%, rgba(200, 200, 200, 0.3) 100%)",
    speedSliderShadow: "4px 4px 10px 0px #A1A1B299, -4px -4px 6px 0px #FFFFFF99",
  },
  additionalColors: {
    dark: "#1D2330",
    light: "#777D8A",
    border: "#a0adb833",
    border1: "#777D8A",
    border1Light: "#c8c8c84d",
    databasecardBorder: "1px solid #d4d7e2",
  },
  palette: {
    mode: "light",
    primary: {
      main: "#0166FF",
      dark: "#245785",
      light: "#7BC5FF",
      gradient: "linear-gradient(180deg, #2EA3FF 0%, #F4F4F6 100%)",
      tertiary: "#207DC8",
      activeIcon: "#78ADF4",
    },
    error: {
      main: "#E32E01",
      light: "#FF6F66",
      hover: "#DF5D55",
      active: "#901D01",
      dark: "#911D01",
      icon: "#E42E01",
      text: "#E44A40",
      gradient: "linear-gradient(316.03deg, #760B0B 0%, #D60202 100%)",
      backgroundLight: "rgba(228, 74, 64, 0.1)",
      btnText: "#F5F9FF",
    },
    success: {
      main: "#3B921D",
      status: "#1DB076",
      notificationAccepted: "rgba(59, 146, 29, 0.70)",
      notificationIgnored: "rgba(228, 74, 64, 0.60)",
    },
    background: {
      default: "#EFEFF1",
      paper: "#F4F4F6",
      listBackground: "#F4F4F6",
      success: "rgba(29, 176, 118, 0.1)",
      main: "#F4F4F6",
      light: "#F4F4F6",
      dark: "#EDEDF0",
      barGraphLight: "#2EA3FF",
      barGraphDark: "#1C5D91",
      gradientLight1: "linear-gradient(90deg, #EDEDF0 0%, #F4F4F6 100%)",
      gradientLight2: "linear-gradient(143.11deg, #EDEDF0 4%, #F4F4F6 95.49%)",
      gradientDark: "linear-gradient(316.03deg, #EDEDF0 0%, #F4F4F6 100%)",
      secondaryGradient: "linear-gradient(316.03deg, #EDEDF0 0%, #F4F4F6 100%)",
      tertiaryGradient: "linear-gradient(90deg, #EDEDF0 0%, #F4F4F6 100%)",
      tertiaryDarkGradient: "linear-gradient(90deg, #EDEDF0 0%, #F4F4F6 100%)",
      darkCardBackground:
        "linear-gradient(0deg, #DFDFE4, #DFDFE4),linear-gradient(90deg, rgba(200, 200, 200, 0.3) 0%, rgba(199, 199, 199, 0.3) 53.13%, rgba(200, 200, 200, 0.3) 100%)",
      specificCameraBgColor:
        "linear-gradient(0deg, #EFEFF1, #EFEFF1),linear-gradient(90deg, rgba(200, 200, 200, 0.3) 0%, rgba(199, 199, 199, 0.3) 53.13%, rgba(200, 200, 200, 0.3) 100%)",
      leftCurve: "#D9D9D9",
      serviceCardBackground:
        "linear-gradient(114.43deg, #F0F0F3 16.75%, #E7E7E9 85.85%),linear-gradient(90deg, rgba(200, 200, 200, 0.3) 0%, rgba(199, 199, 199, 0.3) 53.13%, rgba(200, 200, 200, 0.3) 100%)",
      monthCardBackground:
        "linear-gradient(316.03deg, #E3E3E8 0%, #FAFAFA 100%),linear-gradient(90deg, rgba(200, 200, 200, 0.3) 0%, rgba(199, 199, 199, 0.3) 53.13%, rgba(200, 200, 200, 0.3) 100%)",
      serviceContainerShadow: "9px 9px 17px 0px #8D8D9E73,-8px -8px 12px 0px #FFFFFF",
      civiliansIconBoxBackground: "rgba(59, 146, 29, 0.20)",
      activeCamIconBoxBackground: "rgba(25, 120, 205, 0.24)",
      guardsIconBoxBackground: "rgba(241, 119, 66, 0.30)",
    },
    text: {
      default: "#0166FF",
      primary: "#1D2330",
      primaryBtn: "#fff",
      secondary: "#1D2330",
      disabled: "#AFAFAF",
      captionColor: "#454B58",
      defaultInputField: "#444C59",
      hoverInputField: "#444C59",
      activeInputField: "#1D2330",
      errorInputField: "#E44A40",
      defaultStartIcon: "#444C59",
      activeStartIcon: "#2EA3FF",
      titleLabel: "#1D2330",
      threatText: "rgba(228, 46, 1, 1)",
      active: "#05539A",
      hover: "#C7DDFD",
      //typography
      main: "#0166FF",
      light: "#AOADB8",
      dark: "#444C59",
      tableHeader: "#7A7A8A",
      menuColor: "#000000",
    },
    action: {
      active: "#05539A",
      hover: "rgba(255, 255, 255, 0.08)",
      selected: "rgba(255, 255, 255, 0.16)",
      disabled: "#AFAFAF",
      closeAction: "rgba(228, 46, 1, 1)",
      controller: "#DFDFE4",
    },
    checkbox: {
      border: "#1A1B1E",
      lightBackground: "#242C3D",
      darkBorder: "#AFAFAF",
      default: "#0166FF",
      hover: "#0166FF",
      active: "#05539A",
    },
    radioButton: {
      border: "#1A1B1E",
      lightBackground: "#242C3D",
      default: "#2EA3FF",
      hover: "#2EA3FF",
      active: "#05539A",
    },
    toggle: {
      innerCircle: "#2EA3FF",
      outerCircle: "rgba(46, 163, 255, 0.3)",
      base: "#181D27",
      baseDisabled: "#3D4860",
      innerCircleDisabled: "#181D27",
      outerCircleDisabled: "#3D4860",
    },
    divider: "rgba(255, 255, 255, 0.12)",

    //Pagination Style
    pagination: {
      "& .MuiPaginationItem-icon": {
        color: "#0166FF",
        marginBottom: "5px",
        fontSize: "1.5em",
      },

      "& .MuiPaginationItem-root": {
        borderRadius: "4px",
        fontSize: "16px",
        fontWeight: 600,
        color: "#7A7A8A",
        paddingTop: "5px",
      },

      "& .MuiPaginationItem-root.Mui-selected": {
        background:
          "linear-gradient(0deg, #EFEFF1, #EFEFF1),linear-gradient(90deg, rgba(200, 200, 200, 0.3) 0%, rgba(199, 199, 199, 0.3) 53.13%, rgba(200, 200, 200, 0.3) 100%)",
        boxShadow: "2px 2px 6px 0px #8D8D9E99, -2px -2px 8px 0px #FFFFFF",
        color: "#1D2330",
      },
    },
    // Button style
    button: {
      primary: {
        primaryButton: {
          background:
            "linear-gradient(0deg, #EFEFF1, #EFEFF1),linear-gradient(90deg, rgba(200, 200, 200, 0.3) 0%, rgba(199, 199, 199, 0.3) 53.13%, rgba(200, 200, 200, 0.3) 100%)",
          boxShadow: "4px 4px 11px 0px #8D8D9E73, -4px -4px 8px 0px #FFFFFF",
        },
        primaryButtonHover: {
          background:
            "linear-gradient(0deg, #EFEFF1, #EFEFF1),linear-gradient(90deg, rgba(200, 200, 200, 0.3) 0%, rgba(199, 199, 199, 0.3) 53.13%, rgba(200, 200, 200, 0.3) 100%)",
          boxShadow: "-6px -6px 15px #8D8D9E73, 6px 6px 12px #FFFFFF",
        },
      },
      disabledButton: {
        background:
          "linear-gradient(0deg, #AFAFAF, #AFAFAF),linear-gradient(90deg, rgba(200, 200, 200, 0.3) 0%, rgba(199, 199, 199, 0.3) 53.13%, rgba(200, 200, 200, 0.3) 100%)",
        color: "#DFDFE4",
      },
    },
  },
};

export const darkTheme = {
  ...commons,
  sidebarMenu: {
    background: "#1e2331",
    itemIconColor: "inherit",
    itemTextColor: "#C7DDFD",
    itemActiveBackground: "linear-gradient(143.11deg, rgb(36 43 58) 4%, rgb(35 42 58) 95.49%)",
    itemBackground: "initial",
    itemActiveBorderColor: "#383f50",
    itemActiveRightBorderColor: "transparent",
    itemActiveTopCurveBoxShadow: "#242b3b",
    itemActiveBottomBoxShadow: "#242b3b",
    itemActiveTopCurveBorderColor: "#383f50",
    itemActiveBottomBorderColor: "#383f50",
    itemActiveFakeBorderColor: "#242b3b",
    itemBorderRightColor: "#383f50",
  },
  dashboardContainer: {
    firstThemeColor: "#242b3b",
    secondThemeColor: "#383f50",
    thirdThemeColor: "#1e2331",
    backround: "linear-gradient(143.11deg, #262E3F 4%, #1D2330 95.49%)",
    boxShadow: "-10px -10px 20px rgba(48, 62, 90, 0.55), 10px 10px 20px #12161D",
    borderColor: "#383f50",
    insetBoxShadow: "inset -4px -4px 8px #282F47, inset 4px 4px 14px #161926",
    outsetBoxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.45), -4px -4px 7px 0px rgba(46, 61, 83, 0.60)",
    navbarCurveColorMD: "#262E3F",
    navbarCurveColorXS: "#1D2330",
    navbarCurveLeftColor: "#161926",
    navbarCurveBoxShadow: "-3.5px 0px 6px 0px #282F47",
    cardTitleColorActive: "#C7DDFD",
    cardTitleColor: "#5A80AA",
    closeButtonColor: "linear-gradient(315deg, #252E43 0%, #171D2B 72.02%, #111722 100%)",
    closeButtonBoxShadow: "6px 6px 15px 0px #12161D, -2px -2px 6px 0px rgba(48, 62, 90, 0.60)",
    customDividerBoxShadow: "0px 1px 2px #41527D",
    customDividerBorder: "1px solid #1A1B1E",
    customSwitchBoxShadow: "inset -3px -2px 3px #282F47, inset 3px 2px 12px #161926",
    themeButtonBackground: "linear-gradient(315deg, #252E43 0%, #171D2B 72.02%, #111722 100%)",
    themeButtonBoxShadow: "6px 6px 15px 0px #12161D, -2px -2px 6px 0px rgba(48, 62, 90, 0.60)",
    errorButtonBoxShadow: "4px 4px 6px 0px rgba(0, 0, 0, 0.25), -3px -3px 8px 0px #2E3D53",
    customButtonBackgroundColor: "linear-gradient(316.03deg, #252E43 0%, #111722 90%)",
    customButtonBoxShadow: "-4px -4px 6px rgba(48, 62, 90, 0.55), 10px 10px 20px #12161D",
    customButtonActiveBoxShadow: "0px 0px 1px 0px rgba(18, 22, 29, 0.70), 0px 0px 1px 0px rgba(48, 62, 90, 0.30)",
    customPendingButtonBackground: "rgba(241, 119, 66, 0.1)",
    customCardInsetBoxShadow: "4px 4px 14px 0px #161926 inset, -4px -4px 8px 0px #282F47 inset",
    shareMenuDropdownBoxShadow: "1px 1px 6px 0px #000000BF, -1px -1px 6px 0px #2E3D53CC",
    primaryButtonBackgroundColor: "linear-gradient(135.91deg, rgba(37, 46, 67, 0.2) 0.21%, rgba(17, 23, 34, 0.2) 99.96%)",
    primaryButtonBoxShadow: "-6px -6px 15px #2E3D53, 6px 6px 12px rgba(0, 0, 0, 0.25)",
    commentsFieldBackgroundColor: "#1D2330",
    commentsFieldBoxShadow: "4px 4px 14px 0px #161926 inset,-4px -4px 8px 0px #282F47 inset",
    customInputBoxShadow: "inset -2px -2px 6px rgba(54, 61, 84, 0.56), inset 2px 2px 8px #161926",
    tableBorder: "#41527D",
    customRadioDropdownBackground:
      "linear-gradient(90deg, #252D3D 0%, #1D2330 100%),linear-gradient(135.31deg, rgba(176, 182, 193, 0.15) 0.52%, rgba(70, 77, 92, 0.15) 50.78%, rgba(176, 182, 193, 0.15) 100%)",
    customRadioDropdownBoxShadow: "-1px -1px 6px rgba(46, 61, 83, 0.8), 1px 1px 6px rgba(0, 0, 0, 0.75)",
    areaCameraCardBackgroundColor: "#1D2330",
    authBackground: "linear-gradient(143.11deg, #262E3F 4%, #1D2330 95.49%)",
    authBoxShadow: "14px 14px 37px 0px #12161D,-14px -14px 35px 0px #3E4D69A3",
    unactiveStatusButtonTextColor: "#C7DDFD",
    verticalDividerBoxShadow: "0px 0 8px #2E3D53, 0px 0px 8px #2E3D53",
    verticalDividerColor: "transparent",
    storageCardBackground: "#1D2330",
    containerBackground:
      "radial-gradient(72.99% 100% at 50% 0%, rgba(46, 163, 255, 0.09) 0%, rgba(46, 163, 255, 0) 78.65%, rgba(46, 163, 255, 0) 100%)",
  },
  playerCameraDrawer: {
    playCameraDrawerBackgroundColor: "linear-gradient(90deg, #1c222f 0%, #1D2330 100%)",
    playCameraDrawerBoxShadow: "25px 0px 51px rgba(0, 0, 0, 0.25)",
    playCameraDrawerBorderColor: "#383f5054",
    selectionCardBackgroundColor: "linear-gradient(141deg, #262E3F 0%, #1D2330 100%)",
    selectionCardBoxShadow: "10px 10px 17px 0px rgba(18, 22, 29, 0.70), -6px -6px 11px 0px rgba(48, 62, 90, 0.45)",
    playButtonBoxShadow: "-6px -6px 15px #2E3D53, 6px 6px 12px rgba(0, 0, 0, 0.25)",
  },
  playerLocationDrawer: {
    playLocationDrawerBackgroundColor: "linear-gradient(90deg, #1c222f 0%, #1D2330 100%)",
    playLocationDrawerBoxShadow: "25px 0px 51px rgba(0, 0, 0, 0.25)",
    playLocationDrawerBorderColor: "#383f5054",
    selectionCardBackgroundColor: "linear-gradient(141deg, #262E3F 0%, #1D2330 100%)",
    selectionCardBoxShadow: "10px 10px 17px 0px rgba(18, 22, 29, 0.70), -6px -6px 11px 0px rgba(48, 62, 90, 0.45)",
    playButtonBoxShadow: "-6px -6px 15px #2E3D53, 6px 6px 12px rgba(0, 0, 0, 0.25)",
    peopleDropdownBackgroundColor: "linear-gradient(135deg, rgba(38, 46, 63, 0.70) 0%, rgba(29, 35, 48, 0.70) 100%)",
    peopleDropdownBoxShadow: "4px 4px 10px 0px rgba(18, 22, 29, 0.60), -2px -2px 8px 0px rgba(48, 62, 90, 0.45)",
  },
  cameraBox: {
    headBackgroundColor: "linear-gradient(0deg, #1D2330, #1D2330),linear-gradient(135deg, rgba(116, 143, 165, 0.2) 6.48%, #1D3548 100%)",
    labelColor: "#C7DDFD",
    boxShadowColor: "#3D4860",
    defaultBorder: "1px solid rgba(116, 143, 165, 0.20)",
    selectedBorder: "1px solid #2EA3FF",
    headerBackgroundColor: "linear-gradient(0deg, #1D2330, #1D2330),linear-gradient(135deg, rgba(116, 143, 165, 0.2) 6.48%, #1D3548 100%)",
    playerControlBackgroundColor: "linear-gradient(90deg, #252D3D 0%, #1D2330 100%)",
    // playerControlBoxShadowColor: "0px -9px 4px rgba(0, 0, 0, 0.28)",
    seekbarBoxShadow: "inset -2px -2px 3px #282F47, inset 2px 2px 16px #0E0E0F",
    playerControlButtonBoxShadow: "-2px -2px 6px rgba(48, 62, 90, 0.9), 3px 3px 10px #12161D",
    playerControlButtonColor: "linear-gradient(316.03deg, #252E43 6.25%, #111722 100%)",
    playerControlBorderColor: "#383f50",
    liveButtonBackgroundColor: "linear-gradient(140.59deg, rgba(38, 46, 63, 0.85) 9.18%, rgba(29, 35, 48, 0.85) 97.37%)",
    liveButtonBoxShadow: "inset -1px -1px 2px rgba(21, 23, 29, 0.3), inset 1px 1px 2px rgba(101, 133, 203, 0.1)",
    cameraControlButtonBackgroundColor: "linear-gradient(316.03deg, rgba(37, 46, 67, 0.8) 0%, rgba(17, 23, 34, 0.8) 100%)",
    speedsliderbgColor: "linear-gradient(121.46deg, #2EA3FE 10.95%, #102E48 94.53%)",
    speedSliderShadow: "-4px -4px 9px rgba(48, 62, 90, 0.55), 10px 10px 20px #12161D",
  },
  additionalColors: {
    dark: "#05479A",
    light: "#5A80AA",
    border: "#a0adb833",
    border1: "#383f50",
    border1Light: "#383f5054",
    databasecardBorder: "1px solid #1D3548",
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#2EA3FF",
      dark: "#245785",
      light: "#7BC5FF",
      gradient: "linear-gradient(180deg, #2EA3FF 0%, #031F35 100%)",
      tertiary: "#245785",
      activeIcon: "#05539A",
    },
    error: {
      main: "#E32E01",
      light: "#FF6F66",
      hover: "#DF5D55",
      active: "#901D01",
      dark: "#911D01",
      icon: "#E42E01",
      text: "#E44A40",
      gradient: "linear-gradient(316.03deg, #760B0B 0%, #D60202 100%)",
      backgroundLight: "rgba(228, 74, 64, 0.1)",
      btnText: "#F5F9FF",
    },
    success: {
      main: "#3B921D",
      status: "#1DB076",
      notificationAccepted: "rgba(59, 146, 29, 0.70)",
      notificationIgnored: "rgba(228, 74, 64, 0.60)",
    },
    background: {
      default: "#1D2330",
      paper: "#1D2330",
      listBackground: "rgba(29, 35, 48, 0.81)",
      success: "rgba(29, 176, 118, 0.1)",
      main: "#1E2533",
      light: "#30384A",
      dark: "#181D27",
      barGraphLight: "#2EA3FF",
      barGraphDark: "#1C5D91",
      gradientLight1: "linear-gradient(90deg, #252D3D 0%, #1D2330 100%)",
      gradientLight2: "linear-gradient(143.11deg, #262E3F 4%, #1D2330 95.49%)",
      gradientDark: "linear-gradient(316.03deg, #252E43 0%, #111722 100%)",
      secondaryGradient: "linear-gradient(316.03deg, #323F5B 0%, #111722 100%)",
      tertiaryGradient: "linear-gradient(90deg, #38445E 0%, #1D2330 100%)",
      tertiaryDarkGradient: "linear-gradient(90deg, #222938 0%, #181D27 100%)",
      darkCardBackground:
        "linear-gradient(0deg, #1D2330, #1D2330),linear-gradient(135.31deg, rgba(176, 182, 193, 0.15) 0.52%, rgba(70, 77, 92, 0.15) 50.78%, rgba(176, 182, 193, 0.15) 100%);",
      specificCameraBgColor:
        "linear-gradient(0deg, #1D2330, #1D2330),linear-gradient(135.31deg, rgba(176, 182, 193, 0.15) 0.52%, rgba(70, 77, 92, 0.15) 50.78%, rgba(176, 182, 193, 0.15) 100%)",
      leftCurve: "#161926",
      serviceCardBackground:
        "linear-gradient(90deg, #38445E 0%, #1D2330 100%),linear-gradient(135.31deg, rgba(176, 182, 193, 0.15) 0.52%, rgba(70, 77, 92, 0.15) 50.78%, rgba(176, 182, 193, 0.15) 100%)",
      monthCardBackground:
        "linear-gradient(316.03deg, #323F5B 0%, #111722 100%),linear-gradient(135.31deg, rgba(176, 182, 193, 0.15) 0.52%, rgba(70, 77, 92, 0.15) 50.78%, rgba(176, 182, 193, 0.15) 100%)",
      serviceContainerShadow: "box-shadow: 10px 10px 20px 0px #12161D,-4px -4px 6px 0px #303E5A8C",
      civiliansIconBoxBackground: "rgba(59, 146, 29, 0.10)",
      activeCamIconBoxBackground: "rgba(29, 150, 176, 0.10)",
      guardsIconBoxBackground: "rgba(241, 119, 66, 0.10)",
    },
    text: {
      default: "#2EA3FF",
      primary: "#fff",
      primaryBtn: "#fff",
      secondary: "rgba(255, 255, 255, 0.7)",
      disabled: "rgba(255, 255, 255, 0.5)",
      captionColor: "#A0ADB8",
      defaultInputField: "#444C59",
      hoverInputField: "#444C59",
      activeInputField: "#C7DDFD",
      errorInputField: "#E44A40",
      defaultStartIcon: "#444C59",
      activeStartIcon: "#2EA3FF",
      titleLabel: "#C7DDFD",
      threatText: "rgba(228, 46, 1, 1)",
      active: "#05539A",
      hover: "#C7DDFD",
      //typography
      main: "#C7DDFD",
      light: "#AOADB8",
      dark: "#444C59",
      tableHeader: "#5A80AA",
      menuColor: "#FFFFFF",
    },
    action: {
      active: "#05539A",
      hover: "rgba(255, 255, 255, 0.08)",
      selected: "rgba(255, 255, 255, 0.16)",
      disabled: "#3D4860",
      closeAction: "rgba(228, 46, 1, 1)",
      controller: "rgba(24, 29, 39, 0.8)",
    },
    checkbox: {
      border: "#1A1B1E",
      lightBackground: "#242C3D",
      darkBorder: "#3D4860",
      default: "#2EA3FF",
      hover: "#2EA3FF",
      active: "#05539A",
    },
    radioButton: {
      border: "#1A1B1E",
      lightBackground: "#242C3D",
      default: "#2EA3FF",
      hover: "#2EA3FF",
      active: "#05539A",
    },
    toggle: {
      innerCircle: "#2EA3FF",
      outerCircle: "rgba(46, 163, 255, 0.3)",
      base: "#181D27",
      baseDisabled: "#3D4860",
      innerCircleDisabled: "#181D27",
      outerCircleDisabled: "#3D4860",
    },
    divider: "rgba(255, 255, 255, 0.12)",

    //Pagination Style
    pagination: {
      "& .MuiPaginationItem-icon": {
        color: "#2EA3FF",
        marginBottom: "5px",
        fontSize: "1.5em",
      },

      "& .MuiPaginationItem-root": {
        borderRadius: "4px",
        fontSize: "16px",
        fontWeight: 600,
        color: "#5A80AA",
        paddingTop: "5px",
      },

      "& .MuiPaginationItem-root.Mui-selected": {
        background:
          "linear-gradient(0deg, #1D2330, #1D2330),linear-gradient(135.31deg, rgba(176, 182, 193, 0.15) 0.52%, rgba(70, 77, 92, 0.15) 50.78%, rgba(176, 182, 193, 0.15) 100%)",
        boxShadow: "6px 6px 15px 0px #12161D, -2px -2px 6px 0px #303E5A99",
        color: "#C7DDFD",
      },
    },

    // Button style
    button: {
      primary: {
        primaryButton: {
          background: "linear-gradient(135.91deg, rgba(37, 46, 67, 0.2) 0.21%, rgba(17, 23, 34, 0.2) 99.96%)",
          boxShadow: "-3px -2px 8px #2E3D53, 6px 6px 12px rgba(0, 0, 0, 0.25)",
        },
        primaryButtonHover: {
          background: "linear-gradient(135.91deg, rgba(37, 46, 67, 0.2) 0.21%, rgba(17, 23, 34, 0.2) 99.96%)",
          boxShadow: "-6px -6px 15px #2E3D53, 6px 6px 12px rgba(0, 0, 0, 0.25)",
        },
      },
      disabledButton: {
        background:
          "linear-gradient(0deg, #3D4860, #3D4860),linear-gradient(135.31deg, rgba(176, 182, 193, 0.15) 0.52%, rgba(70, 77, 92, 0.15) 50.78%, rgba(176, 182, 193, 0.15) 100%)",
        color: "#181D27",
      },
    },
  },
};
