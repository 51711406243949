import { useTheme } from "@emotion/react";
import Box from "@mui/material/Box";
import React from "react";
import { routes } from "../../../routes";

export default function SidebarLogo({ isDarkTheme }) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: "transparent",
        color: "white",
        height: "50px",
        width: "50px",
        mt: 3,
        textAlign: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "0.5rem",
          height: { sm: "130%", md: "142%" },
          borderLeft: `2px solid ${theme.dashboardContainer.secondThemeColor}`,
          right: "-29px",
          bottom: "-24px",
          borderTopLeftRadius: "10px",
        }}
      ></Box>
      <a href={routes.root.path}>
        <img src={isDarkTheme ? "/skylarklab_logo_black.png" : "/skylarklab_logo_for_light.png"} alt="logo" />
      </a>
    </Box>
  );
}
