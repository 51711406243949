import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveInferenceScoreFeedback } from "../../../store/core/coreThunk";
import { toast } from "react-toastify";

const INFERENCE_SCORE_CLASSES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const HOTSPOT_SCORE_CLASSES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export default function FeedbackButtons({ lat, lng }) {
  const dispatch = useDispatch();
  const { liveAccidentScore, generalInfo, customTrafficDensity } = useSelector((state) => state.core);

  // Function to generate color based on ID range (1 to 10)
  const getColorCodeById = (id) => {
    // Ensure ID is within the range of 1 to 10
    id = Math.max(1, Math.min(id, 10));

    // Calculate color based on ID position (1 is green, 10 is red)
    const startColor = [0, 128, 0]; // Green (RGB: 0, 128, 0)
    const endColor = [255, 0, 0]; // Red (RGB: 255, 0, 0)

    const stepR = (endColor[0] - startColor[0]) / 9; // Total of 10 colors (0-9)
    const stepG = (endColor[1] - startColor[1]) / 9;
    const stepB = (endColor[2] - startColor[2]) / 9;

    const r = Math.round(startColor[0] + stepR * (id - 1));
    const g = Math.round(startColor[1] + stepG * (id - 1));
    const b = Math.round(startColor[2] + stepB * (id - 1));

    return `rgb(${r}, ${g}, ${b})`;
  };

  const handleSubmitFeedback = (scoreClass, type = "Inference") => {
    dispatch(
      saveInferenceScoreFeedback({
        lat: lat,
        lng: lng,
        label: String(scoreClass),
        inference_score: liveAccidentScore,
        type,
        parameters: {
          ...generalInfo,
          traffic_density: customTrafficDensity === "default" || !customTrafficDensity ? generalInfo?.traffic_density : customTrafficDensity,
        },
      }),
    ).then((res) => {
      if (res?.payload?.status === 200) {
        toast.success(`Submitted the label: ${scoreClass}.`);
      }
    });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography variant="caption">Hotspot Score:</Typography>
        <ButtonGroup variant="contained" sx={{ flexWrap: "wrap", justifyContent: "center", gap: 1, boxShadow: "none" }}>
          {HOTSPOT_SCORE_CLASSES.map((v_, index) => (
            <Button onClick={() => handleSubmitFeedback(v_, "Hotspot")} sx={{ backgroundColor: getColorCodeById(index + 1) }} size="medium">
              {v_}
            </Button>
          ))}
        </ButtonGroup>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography variant="caption">Accident Score:</Typography>
        <ButtonGroup variant="contained" sx={{ flexWrap: "wrap", justifyContent: "center", gap: 1, boxShadow: "none" }}>
          {INFERENCE_SCORE_CLASSES.map((v_, index) => (
            <Button onClick={() => handleSubmitFeedback(v_)} sx={{ backgroundColor: getColorCodeById(index + 1) }} size="medium">
              {v_}
            </Button>
          ))}
        </ButtonGroup>
      </Box>
    </Box>
  );
}
