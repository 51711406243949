import { useTheme } from "@emotion/react";
import { Box, Grid } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import PrimaryLoadingButton from "../../../../components/PrimaryLoadingButton";
import { sendFeedBack } from "../../../../store/core/coreThunk";

export default function FeedbackSection({ handleClick }) {
  const theme = useTheme();
  const { vehicleLocation } = useSelector((state) => state.core);
  const dispatch = useDispatch();

  const handleReport = (feedback ,event) => {
    const value = {
      lat: vehicleLocation?.geometry?.location?.lat,
      lng: vehicleLocation?.geometry?.location?.lng,
      feedback: String(feedback),
    };
    dispatch(sendFeedBack(value)).then((res) => {
      if (res?.payload?.status === 201) {
        toast.success(`Submitted the report.`);
      }
    });
    handleClick(event);
  };

  return (
    <Box
      sx={{
        borderTop: "1px solid",
        pt: 2,
        height: { xs: "50vh", md: "55vh", xl: "50vh" },
        borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
      }}
    >
      {/* <Box width="100%" height="100%" display="flex" flexDirection="column" justifyContent="space-between">

      </Box> */}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box sx={{ height: 100 }}>
            <PrimaryLoadingButton sx={{ height: "100%", width: "100%" }} onClick={(e) => handleReport(1, e)}>
              Road Maintenance
            </PrimaryLoadingButton>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ height: 100 }}>
            <PrimaryLoadingButton sx={{ height: "100%", width: "100%" }} onClick={(e) => handleReport(2, e)}>
              Accidents
            </PrimaryLoadingButton>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ height: 100 }}>
            <PrimaryLoadingButton sx={{ height: "100%", width: "100%" }} onClick={(e) => handleReport(3, e)}>
              Road Hazards
            </PrimaryLoadingButton>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ height: 100 }}>
            <PrimaryLoadingButton sx={{ height: "100%", width: "100%" }} onClick={(e) => handleReport(4, e)}>
              Road Closures
            </PrimaryLoadingButton>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ height: 100 }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
              <PrimaryLoadingButton sx={{ height: "100%", width: "50%" }} onClick={(e) => handleReport(5, e)}>
                Construction
              </PrimaryLoadingButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
