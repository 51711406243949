import React from "react";
import { Popper } from "@mui/base/Popper";
import { Box, useTheme } from "@mui/material";

import  FeedbackSection  from "./FeedbackSection";

export default function FeedbackPopper({ id, open, anchorEl , handleClick}) {
  const theme = useTheme();
  return (
    <Box>
      <Popper id={id} open={open} anchorEl={anchorEl} popperOptions={{ placement: "top-end" }} style={{ zIndex: "100" }}>
        <Box
          sx={{
            background: theme.palette.background.dark,
            p: 3,
            mb: 1,
            zIndex: 2,
            width: "32rem",
            height: "100%",
            position: "relative",
            borderRadius: "18px",
            border: `1px solid ${theme.palette.primary.dark}`,
          }}
        >
          <FeedbackSection handleClick={handleClick} />
        </Box>
      </Popper>
    </Box>
  );
}
