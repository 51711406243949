import { configureStore } from "@reduxjs/toolkit";
import coreSlice from "./core/coreSlice";
import dashboardSlice from "./dashboard/dashboardSlice";
import themeSlice from "./ui/themeSlice";

const store = configureStore({
  reducer: {
    theme: themeSlice.reducer,
    core: coreSlice.reducer,
    dashboard: dashboardSlice.reducer,
  },
  middleware: (
    getDefaultMiddleware, // To remove response headers should not be non-serializable error while returning promise in action creators.
  ) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
