import { useTheme } from "@emotion/react";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";

import { toast } from "react-toastify";
import PrimaryLoadingButton from "../../../../components/PrimaryLoadingButton";
import { reportCrash } from "../../../../store/dashboard/dashboardThunk";

export default function FeedbackSection({ handleClick }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const road_incidents = ["Road Maintenance", "Road Accident", "Road Closure", "Road Construction"];

  const road_hotspots = [
    "Heavy Traffic Volume",
    "Poor Road Conditions",
    "Sharp Turns and Curves",
    "Inadequate Signage",
    "Intersections and Junctions",
    "Pedestrian Crossings",
    "Speeding Zones",
    "Visibility Issues",
    "Frequent Lane Changes",
    "Road Construction Zones",
    "High Incidence of Drunk Driving",
    "High Volume of Transport Vehicles",
    "Weather Conditions",
  ];

  const handleReport = (feedback, event) => {
    const value = {
      name: feedback,
    };
    dispatch(reportCrash(value)).then((res) => {
      if (res?.payload?.status === 201) {
        toast.success(`Submitted the report.`);
      }
    });
    handleClick(event);
  };

  return (
    <>
      <Box
        sx={{
          height: "50vh",
          overflowY: "auto",
          scrollbarWidth: "thin",
          scrollbarColor: `${theme.palette.primary.main} transparent`,
          pr: 2,
        }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Report Road Incidents
        </Typography>
        <Box
          sx={{
            pt: 2,
            borderTop: "1px solid",
            borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
          }}
        >
          <Grid container spacing={2}>
            {road_incidents.map((incident, index) => (
              <Grid item xs={6} key={index}>
                <Box sx={{ height: 40 }}>
                  <PrimaryLoadingButton sx={{ height: "100%", width: "100%" }} onClick={(e) => handleReport(incident, e)}>
                    {incident}
                  </PrimaryLoadingButton>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Typography variant="h6" sx={{ mb: 2, my: 4 }}>
            Hotspot Markers
          </Typography>
          <Grid container spacing={2} sx={{
            borderTop: "1px solid",
            borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
          }}>
            {road_hotspots.map((hotspot, index) => (
              <Grid item xs={6} key={index}>
                <Box sx={{ height: 40 }}>
                  <PrimaryLoadingButton sx={{ height: "100%", width: "100%", lineHeight: "normal" }} onClick={(e) => handleReport(hotspot, e)}>
                    {hotspot}
                  </PrimaryLoadingButton>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
