import { Box, Grid, Paper, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { GoAlert } from "react-icons/go";
import CustomButton from "../../components/CustomButton";

import { useDispatch, useSelector } from "react-redux";
import { coreActions } from "../../store/core/coreSlice";
import { getFeedbackPoints, getHotspotPoints } from "../../store/core/coreThunk";
import { TYPE_CHOICES, WS_BASE_URL } from "../../utils/constants";
import FeedbackWidget from "./components/Feedback/FeedbackWidget";
import FeedbackButtons from "./components/FeedbackButtons";
import GeneralInfo from "./components/GeneralInfo";
import GoogleMap from "./components/GoogleMap";
import KnownIssues from "./components/KnownIssues";
import RoadInfo from "./components/RoadInfo";
import TrafficDensitySelector from "./components/TrafficDensitySelector";
import WeatherInfo from "./components/WeatherInfo";

const DEFAULT_VEHICLE_POSITION = { lat: 18.597133486456183, lng: 73.71882447328774 };
const DEFAULT_DESTINATION_POSITION = { dest_lat: 0, dest_lng: 0 };

export default function Home({ isFeedback = true }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const websocket = React.useRef();
  const { liveAccidentScore, anticipatedVehicleLocation, feedbackPoints } = useSelector((state) => state.core);
  const [vehiclePosition, setVehiclePosition] = React.useState(DEFAULT_VEHICLE_POSITION);
  const [destinationPosition, setDestinationPosition] = React.useState(DEFAULT_DESTINATION_POSITION);
  const [vehicleData, setVehicleData] = React.useState({});
  const [isAlert, setIsAlert] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [feedbackIndex, setFeedbackIndex] = useState();
  const [alertTimeoutHandle, setAlertTimeoutHandle] = useState(null);
  // console.log("showAlert :", showAlert);
  // React.useEffect(() => {
  //   if (isAlert.length > 0) {
  //     setShowAlert(true);
  //     setAlertMessage(isAlert.map((index) => TYPE_CHOICES[parseInt(feedbackPoints[index].feedback) - 1]).join(", "));
  //     setFeedbackIndex(isAlert[0]);
  //   }
  //   // check if vechicle in the feedback location
  //   console.log("isAlert :", isAlert);
  //   if (feedbackIndex) {
  //     const rangeLocationOfFeedback = rangeArea(
  //       {
  //         latitude: feedbackPoints[feedbackIndex].latitude,
  //         longitude: feedbackPoints[feedbackIndex].longitude,
  //       },
  //       100,
  //     );
  //     console.log("Executing for vehicle location ", vehiclePosition);
  //     if (
  //       rangeLocationOfFeedback.lat_upper >= vehiclePosition.lat &&
  //       rangeLocationOfFeedback.lat_lower <= vehiclePosition.lat &&
  //       rangeLocationOfFeedback.lng_upper >= vehiclePosition.lng &&
  //       rangeLocationOfFeedback.lng_lower <= vehiclePosition.lng
  //     ) {
  //       console.log("Alert arrived :", feedbackPoints[feedbackIndex].feedback);
  //       setShowAlert(false);
  //       setAlertMessage("");
  //       setFeedbackIndex(undefined);
  //     }
  //   }
  // }, [isAlert]);

  React.useEffect(() => {
    if (isAlert.length > 0) {
      setShowAlert(true);
      setAlertMessage(isAlert.map((index) => TYPE_CHOICES[parseInt(feedbackPoints[index].feedback) - 1]).join(", "));

      const timer = setTimeout(() => {
        setAlertMessage("");
        setShowAlert(false);
      }, 5000);

      setAlertTimeoutHandle(timer);
    }
  }, [isAlert]);

  // Live Location Update: We can update the live location using this
  // React.useEffect(() => {
  //   if (destinationPosition && currentPathPolyline.length > 0) {
  //     let index = 0;
  //     setVehiclePosition(currentPathPolyline[index]);
  //     const intervalId = setInterval(() => {
  //       if (index < currentPathPolyline.length - 1) {
  //         setVehiclePosition(() => {
  //           index++;
  //           console.log(index, currentPathPolyline.length);
  //           return currentPathPolyline[index];
  //         });
  //       }
  //     }, 1000); // Update every 10 second
  //     return () => clearInterval(intervalId); // Clean up on unmount
  //   }
  // }, [destinationPosition, currentPathPolyline]);

  React.useEffect(() => {
    const ws = new WebSocket(`${WS_BASE_URL}/ws/inference/`);
    websocket.current = ws;

    ws.onopen = () => {
      console.log("connected to websocket");
    };

    ws.onmessage = function (e) {
      const recievedData = JSON.parse(e.data);
      // console.log("recievedData", recievedData["message"]["vehicle_speed"]);
      if (recievedData["type"] === "live_location") {
        setVehiclePosition(recievedData["message"]);
      }
      if (recievedData["type"] === "vehicle_data") {
        const data_ = {
          vehicle_speed: recievedData["message"]["vehicle_speed"] || 0,
          vehicle_accelerator_pos_d: recievedData["message"]["vehicle_accelerator_pos_d"] || 0,
          vehicle_accelerator_pos_e: recievedData["message"]["vehicle_accelerator_pos_e"] || 0,
          vehicle_rpm: recievedData["message"]["vehicle_rpm"] || 0,
          vehicle_relative_throttle_pos: recievedData["message"]["vehicle_relative_throttle_pos"] || 0,
          vehicle_relative_accel_pos: recievedData["message"]["vehicle_relative_accel_pos"] || 0,
          vehicle_engine_load: recievedData["message"]["vehicle_engine_load"] || 0,
          vehicle_fuel_status: recievedData["message"]["vehicle_fuel_status"] || 0,
          vehicle_run_time: recievedData["message"]["vehicle_run_time"] || 0,
        };
        setVehicleData(data_);
        dispatch(coreActions.updateGeneralInfo(data_));
      }
      if (recievedData["type"] === "inference") {
        dispatch(coreActions.updateLiveAccidentScore(recievedData["message"]["accident_score"]));
        dispatch(coreActions.updateGeneralInfo(recievedData["message"]["general_info"]));
      }
    };

    ws.onclose = function (e) {
      console.error("Chat socket closed unexpectedly", e);
    };

    ws.onerror = (err) => {
      console.error("Socket encountered error: ", err.message, "Closing socket");
      ws.close();
    };

    return () => {
      ws.close();
    };
  }, []);

  React.useEffect(() => {
    dispatch(getFeedbackPoints());
  }, []);

  React.useEffect(() => {
    dispatch(getFeedbackPoints());
    dispatch(getHotspotPoints());
  }, []);

  React.useEffect(() => {
    console.log("Sending inference request");
    if (websocket?.current) {
      if (anticipatedVehicleLocation && "lat" in anticipatedVehicleLocation && "lng" in anticipatedVehicleLocation) {
        websocket.current.send(
          JSON.stringify({
            type: "inference",
            message: { anticipated_location: anticipatedVehicleLocation, destination: destinationPosition, vehicle: vehicleData },
          }),
        );
      }

      const intervalId = setInterval(() => {
        if (anticipatedVehicleLocation && "lat" in anticipatedVehicleLocation && "lng" in anticipatedVehicleLocation) {
          websocket.current.send(
            JSON.stringify({
              type: "inference",
              message: { anticipated_location: anticipatedVehicleLocation, destination: destinationPosition, vehicle: vehicleData },
            }),
          );
        }
      }, 3000); // Update every n second
      return () => clearInterval(intervalId); // Clean up on unmount
    }
  }, [destinationPosition, anticipatedVehicleLocation, vehicleData, websocket]);

  // React.useEffect(() => {
  //   // dispatch(getGeneralInfo({...vehiclePosition,...vehicleData}));
  //   if (anticipatedVehicleLocation && "lat" in anticipatedVehicleLocation && "lng" in anticipatedVehicleLocation) {
  //     dispatch(getAccidentScore({ ...anticipatedVehicleLocation, ...destinationPosition, vehicle_data: JSON.stringify(vehicleData) }));
  //   }

  //   const intervalId = setInterval(() => {
  //     // dispatch(getGeneralInfo({...vehiclePosition,...vehicleData}));
  //     if (anticipatedVehicleLocation && "lat" in anticipatedVehicleLocation && "lng" in anticipatedVehicleLocation) {
  //       dispatch(getAccidentScore({ ...anticipatedVehicleLocation, ...destinationPosition, vehicle_data: JSON.stringify(vehicleData) }));
  //     }
  //     // console.log({ ...anticipatedVehicleLocation, ...vehicleData });
  //   }, 5000); // Update every n second

  //   return () => clearInterval(intervalId); // Clean up on unmount
  // }, [destinationPosition, anticipatedVehicleLocation, vehicleData]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} sx={{ display: "flex", justifyContent: "flex-start", gap: 2 }}>
          <Box>
            <img src="/skylarklab_logo_black.png" alt="logo" width={"50px"} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>
            <Typography variant="h1">Accident Hotspot Detection</Typography>
            <Typography variant="h4" fontWeight="bold" color={theme.palette.primary.main}>
              Powered by Skylark Labs
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
            <CustomButton
              onClick={() => { }}
              size="large"
              sx={{ px: [1, 3], py: 1.5, fontSize: "1.4rem", fontWeight: "bold", width: ["100%", "auto"], flexDirection: ["column", "row"] }}
            >
              <Box>Accident Awareness Score&nbsp;:&nbsp;</Box>
              <Box
                color={() =>
                  liveAccidentScore <= 33
                    ? theme.palette.primary.main
                    : liveAccidentScore > 33 && liveAccidentScore <= 66
                      ? theme.palette.warning.main
                      : liveAccidentScore > 66
                        ? theme.palette.error.main
                        : theme.palette.primary.main
                }
              >
                {liveAccidentScore}%
              </Box>
            </CustomButton>
          </Box>
        </Grid>
      </Grid>
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8} sx={{ height: ["80vh", "90vh"] }}>
            <Paper
              sx={{
                background: theme.palette.background.default,
                boxShadow: theme.dashboardContainer.insetBoxShadow,
                borderRadius: "18px",
                paddingTop: "0px",
                height: "100%",
                overflowY: "scroll",
                py: 2,
                px: 2,
                [theme.breakpoints.down("md")]: { boxShadow: "none" },
              }}
            >
              <GoogleMap
                vehicleLat={vehiclePosition.lat}
                vehicleLng={vehiclePosition.lng}
                setDestinationPosition={setDestinationPosition}
                setIsAlert={setIsAlert}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} sx={{ height: ["auto", "90vh"] }}>
            <Paper
              sx={{
                background: theme.palette.background.default,
                boxShadow: theme.dashboardContainer.insetBoxShadow,
                borderRadius: "18px",
                paddingTop: "0px",
                height: "100%",
                overflowY: "scroll",
                py: 2,
                px: 2,
                [theme.breakpoints.down("md")]: { boxShadow: "none" },
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 4, my: 2, mx: 2 }}>
                <RoadInfo />
                {!destinationPosition?.dest_lat && !destinationPosition?.dest_lng && (
                  <Typography variant="caption" color="error">
                    Note: No destination selected, prediction won't be accurate without destination.
                  </Typography>
                )}
                {showAlert && (
                  <Box
                    sx={{ p: 2, borderRadius: "8px", background: "white", display: "flex", alignItems: "center", gap: 1, justifyContent: "center" }}
                  >
                    <GoAlert size={24} color="red" />
                    <Typography variant="h6" color="red">
                      {`Be cautious, there is ${alertMessage} ahead.`}
                    </Typography>
                  </Box>
                )}
                {isFeedback && (
                  <Box
                    sx={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      textWrap: "nowrap",
                    }}
                  >
                    <TrafficDensitySelector />
                  </Box>
                )}
                {isFeedback && <FeedbackButtons lat={vehiclePosition.lat} lng={vehiclePosition.lng} />}
                <KnownIssues />
                <WeatherInfo />
                <GeneralInfo data={vehicleData} />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      {isFeedback && <FeedbackWidget />}
    </Box>
  );
}
