import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import CustomDivider from "../../../components/CustomDivider";
import CustomRadialBar from "../../../components/CustomRadialBar";

export default function GeneralInfo({ data }) {
  return (
    <Box>
      <Typography
        variant="h2"
        sx={(theme) => ({
          fontFamily: theme.typography.fontFamily,
          fontSize: "22px",
          lineHeight: "100%",
        })}
      >
        General Information:
      </Typography>
      <CustomDivider />
      <Grid container spacing={3} mt={0}>
        <Grid item xs={6} md={4}>
          <CustomRadialBar label={"Speed"} series={[data?.vehicle_speed || 0]} text={`${data?.vehicle_speed || 0}`} />
        </Grid>
        <Grid item xs={6} md={4}>
          <CustomRadialBar
            label={"Relative Acceleration"}
            series={[data?.vehicle_relative_accel_pos || 0]}
            text={`${data?.vehicle_relative_accel_pos || 0}`}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <CustomRadialBar label={"RPM"} series={[data?.vehicle_rpm || 0]} text={`${data?.vehicle_rpm || 0}`} />
        </Grid>
        <Grid item xs={6} md={4}>
          <CustomRadialBar
            label={"Relative Throttle"}
            series={[data?.vehicle_relative_throttle_pos || 0]}
            text={`${Math.round((data?.vehicle_relative_throttle_pos || 0) * 100) / 100}`}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <CustomRadialBar
            label={"Accelerator Pos D"}
            series={[data?.vehicle_accelerator_pos_d || 0]}
            text={`${Math.round((data?.vehicle_accelerator_pos_d || 0) * 100) / 100}`}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <CustomRadialBar
            label={"Accelerator Pos E"}
            series={[data?.vehicle_accelerator_pos_e || 0]}
            text={`${Math.round((data?.vehicle_accelerator_pos_e || 0) * 100) / 100}`}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <CustomRadialBar label={"Acceleration Score"} series={[0]} text={"0"} />
        </Grid>
        <Grid item xs={6} md={4}>
          <CustomRadialBar label={"Braking Score"} series={[0]} text={"0"} />
        </Grid>
        <Grid item xs={6} md={4}>
          <CustomRadialBar label={"Airbag Health"} series={[0]} text={"0"} />
        </Grid>
        <Grid item xs={6} md={4}>
          <CustomRadialBar label={"Vehicle Diagnostics"} series={[0]} text={"0"} />
        </Grid>
        <Grid item xs={6} md={4}>
          <CustomRadialBar label={"Steering Score"} series={[0]} text={"0"} />
        </Grid>
        <Grid item xs={6} md={4}>
          <CustomRadialBar label={"Engine Load"} series={[data?.vehicle_engine_load || 0]} text={`${data?.vehicle_engine_load || 0}`} />
        </Grid>
        <Grid item xs={6} md={4}>
          <CustomRadialBar label={"Fuel Status"} series={[data?.vehicle_fuel_status || 0]} text={`${data?.vehicle_fuel_status || 0}`} />
        </Grid>
        <Grid item xs={6} md={4}>
          <CustomRadialBar label={"Run Time"} series={[data?.vehicle_run_time || 0]} text={`${data?.vehicle_run_time || 0}`} />
        </Grid>
      </Grid>
    </Box>
  );
}
