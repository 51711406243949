import { Box } from "@mui/system";

import { useSelector } from "react-redux";
import CustomPaper from "../../../components/CustomPaper";
import { additionalDrawerMenuItems, drawerMenuItems } from "../../../routes";
import DrawerMenu from "./DrawerMenu";
import SidebarLogo from "./SidebarLogo";
import SidebarMenu from "./SidebarMenu";

const drawerWidth = "80px";

const SideBar = () => {
  const { isDarkTheme } = useSelector((state) => state.theme)
  return (
    <>
      <CustomPaper
        sx={(theme) => ({
          alignItems: "center",
          width: drawerWidth,
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          overflowY: "scroll",
          overflowX: "hidden",
          position: "sticky",
          minWidth: "6rem",
          top: 0,
          zIndex: 9999,
          boxShadow: "none",
          backgroundImage: isDarkTheme ? `url(/skylarklabs_full_circle.png)` : `url(/skylarklabs_full_circle_light.png)`,
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        })}
      >
        <SidebarLogo isDarkTheme={isDarkTheme} />
        <SidebarMenu sidebarMenuItems={drawerMenuItems} />
        <Box flex={1} />
        <SidebarMenu sidebarMenuItems={additionalDrawerMenuItems} />
      </CustomPaper>
      <CustomPaper
        sx={(theme) => ({
          position: "sticky",
          top: 0,
          zIndex: 1210,
          boxShadow: "none",
          [theme.breakpoints.up("md")]: {
            display: "none",
          },
        })}
      >
        <DrawerMenu sidebarMenuItems={[...drawerMenuItems, ...additionalDrawerMenuItems]} />
      </CustomPaper>
    </>
  );
};
export default SideBar;
