import AllOutIcon from "@mui/icons-material/AllOut";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import { Box } from "@mui/material";
import { Autocomplete, GoogleMap, InfoWindow, LoadScript, Marker, Polyline } from "@react-google-maps/api";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { deleteCrash } from "../../../store/dashboard/dashboardThunk";
import { GOOGLE_MAP_REACT_API_KEY } from "../../../utils/constants";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const libraries = ["places"];

const MyMapComponent = ({ setDestinationPosition, destinationPosition }) => {

  const dispatch = useDispatch();

  const { coOrdinates, carLocationData } = useSelector((state) => state.dashboard);

  const DEFAULT_CENTER =
    carLocationData?.lat && carLocationData?.lng
      ? { lat: carLocationData.lat, lng: carLocationData.lng }
      : {
        lat: 0,
        lng: 0,
      };

  const [map, setMap] = useState(null);

  const [autocomplete, setAutocomplete] = useState(null);
  const [coOrdsData, setCoOrdsData] = useState(null);
  const [isAutoTracking, setIsAutoTracking] = useState(true);
  const [activeMarker, setActiveMarker] = useState(null);
  const [removedMarkers, setRemovedMarkers] = useState([]);

  const [center, setCenter] = React.useState(DEFAULT_CENTER);

  const [crashDataPoints, setCrashDataPoints] = useState([]);

  const onLoad = useCallback((autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  }, []);

  const handleMarkerClick = useCallback((marker) => {
    setActiveMarker(marker);
  }, []);

  const handleInfoWindowClose = useCallback(() => {
    setActiveMarker(null);
  }, []);

  const getColor = (traffic) => {
    switch (traffic) {
      case "NORMAL":
        return "#00FF00";
      case "SLOW":
        return "#FFAE42";
      case "TRAFFIC_JAM":
        return "#FF6347";
      default:
        return "#00FF00";
    }
  };

  useEffect(() => {
    if (map && isAutoTracking) {
      map.panTo({ lat: carLocationData.lat, lng: carLocationData.lng });
    }
  }, [carLocationData]);

  const handleMapDragEnd = () => {
    setIsAutoTracking(false); // Disable auto tracking when the user manually drags the map
  };

  const handleRecenter = () => {
    if (map) {
      map.panTo(
        carLocationData?.lat && carLocationData?.lng
          ? { lat: carLocationData.lat, lng: carLocationData.lng }
          : { lat: 18.583073229549893, lng: 73.69247005519334 },
      );
      map.setZoom(18);
      setIsAutoTracking(true);
    }
  };

  const handleRecenterAll = () => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      coOrdinates?.coords.forEach((coord) => {
        bounds.extend(new window.google.maps.LatLng(coord[0], coord[1]));
      });

      map.fitBounds(bounds);
      setIsAutoTracking(false);
    }
  };

  function get(obj, key, defaultValue) {
    return obj.hasOwnProperty(key) ? obj[key] : defaultValue;
  }

  const removeMarkerFromState = (report_id) => {
    let newCrashDataPoints = crashDataPoints.filter((crashData) => crashData.report_id !== report_id);
    setCrashDataPoints(newCrashDataPoints);
  };

  const removeMarker = (report_id) => {
    dispatch(deleteCrash({ crash_id: report_id, })).then((res) => {
      if (res?.payload?.status === 200) {
        handleInfoWindowClose();
        removeMarkerFromState(report_id);
        setRemovedMarkers([...removedMarkers, report_id]);
        toast.success(`Removed the report.`);
      }
    });
  };

  React.useEffect(() => {
    if (coOrdinates) {
      let data = coOrdinates.coords;
      let crashObjects = coOrdinates.crash_objects;
      let crashData = {};
      for (let key in crashObjects) {
        if (removedMarkers.includes(crashObjects[key].report_id)) {
          continue;
        }
        let report_id = crashObjects[key].report_id;
        let lat = crashObjects[key].lat;
        let lng = crashObjects[key].lng;
        let order = crashObjects[key].order;
        let crash_lat = crashObjects[key].crash_data[0].lat;
        let crash_lng = crashObjects[key].crash_data[0].lng;
        let crash_data = get(crashData, report_id, { crash_lat, crash_lng, report_id, crash_coords: {} });
        crash_data.crash_coords[order] = { lat, lng };
        crash_data['name'] = crashObjects[key].crash_data[0].name;
        crashData[report_id] = crash_data;
      }
      setCrashDataPoints(Object.values(crashData));

      let locations = [];
      let location_segments = [];

      let segments = [];
      carLocationData && carLocationData.lat && segments.push({ lat: carLocationData.lat, lng: carLocationData.lng });
      let current_segment = data[1]?.[2] || "NORMAL";
      for (let i = 1; i < data.length; i++) {
        locations.push({ lat: data[i - 1][0], lng: data[i - 1][1] });
        if (current_segment == data[i][2]) {
          segments.push({ lat: data[i - 1][0], lng: data[i - 1][1] });
        } else {
          segments.push({ lat: data[i][0], lng: data[i][1] });
          location_segments.push({ points: segments, color: getColor(current_segment) });
          current_segment = data[i][2];
          segments = [{ lat: data[i][0], lng: data[i][1] }];
        }
      }
      location_segments.push({ points: segments, color: getColor(current_segment) });
      if (data[data.length - 1]?.[0] && data[data.length - 1]?.[1] && data[data.length - 2]?.[0] && data[data.length - 2]?.[1]) {
        location_segments.push({ points: [{ lat: data[data.length - 1]?.[0], lng: data[data.length - 1]?.[1] }, { lat: data[data.length - 2]?.[0], lng: data[data.length - 2]?.[1] }], color: getColor(data[data.length - 1][2]) });
      }
      if (destinationPosition && data[data.length - 1]?.[0] && data[data.length - 1]?.[1]) {
        location_segments.push({ points: [{ lat: data[data.length - 1]?.[0], lng: data[data.length - 1]?.[1] }, { lat: destinationPosition.dest_lat, lng: destinationPosition.dest_lng }], color: "#00FF00" });
      }
      setCoOrdsData(location_segments);

      let newRemovedMarkers = removedMarkers.filter((report_id) => {
        return crashDataPoints.some((crashData) => crashData.report_id === report_id);
      });
      setRemovedMarkers(newRemovedMarkers);
    }
  }, [coOrdinates]);

  const onPlaceChanged = useCallback(() => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        console.log("Returned place contains no geometry");
        return;
      }
      setDestinationPosition({ dest_lat: place.geometry.location.lat(), dest_lng: place.geometry.location.lng() });
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  }, [autocomplete]);

  return (
    <LoadScript googleMapsApiKey={GOOGLE_MAP_REACT_API_KEY} libraries={libraries}>
      <GoogleMap
        onLoad={(map) => {
          setMap(map);
        }}
        mapContainerStyle={containerStyle}
        center={center}
        zoom={16}
        onDragEnd={handleMapDragEnd} // Add onDragEnd event handler
        draggableCursor={isAutoTracking ? "auto" : "grab"}
      >
        <button
          onClick={handleRecenter}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            zIndex: "1",
            padding: "8px",
            backgroundColor: "#fff",
            borderRadius: "5px",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
          }}
        >
          <CenterFocusStrongIcon />
        </button>
        <button
          onClick={handleRecenterAll}
          style={{
            position: "absolute",
            top: "60px",
            right: "10px",
            zIndex: "1",
            padding: "8px",
            backgroundColor: "#fff",
            borderRadius: "5px",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
          }}
        >
          <AllOutIcon />
        </button>
        <Box
          className="autocomplete-control"
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box className="autocomplete-container" sx={{ mt: 2, minWidth: "16rem", maxWidth: "min-content" }}>
            <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
              <input
                type="text"
                placeholder="Search for a place"
                style={{
                  boxSizing: `border-box`,
                  border: `1px solid transparent`,
                  width: `240px`,
                  height: `32px`,
                  padding: `28px`,
                  borderRadius: `8px`,
                  boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                  fontSize: `16px`,
                  outline: `none`,
                  textOverflow: `ellipses`,
                  position: "absolute",
                  left: "50%",
                  marginLeft: "-120px",
                  background: "rgb(29, 35, 48)",
                  color: "white",
                }}
              />
            </Autocomplete>
          </Box>
        </Box>
        {coOrdsData?.map((segment, index) => (
          <Polyline key={index} path={segment.points} options={{ strokeColor: segment.color, strokeOpacity: 1.0, strokeWeight: 4 }} />
        ))}
        {crashDataPoints?.map((crashData, index) => {
          let crash_coords = crashData.crash_coords;
          let sortedKeys = Object.keys(crash_coords).sort();
          let sortedValues = sortedKeys.map((key) => crash_coords[key]);
          return (
            <>
              <Marker
                style={{ position: "absolute", transform: "translate(-50%, -100%)" }}
                position={{ lat: crashData.crash_lat, lng: crashData.crash_lng }}
                icon={{
                  url: "https://img.icons8.com/?size=40&id=16238&format=png&color=000000",
                }}
                onClick={() => handleMarkerClick(crashData)}
              />
              <Polyline key={index} path={sortedValues} options={{ strokeColor: "#5C4033", strokeOpacity: 1.0, strokeWeight: 4 }} />
            </>
          );
        })}
        {activeMarker && (
          <InfoWindow
            position={{ lat: activeMarker.crash_lat, lng: activeMarker.crash_lng }}
            onCloseClick={handleInfoWindowClose}
            options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
          >
            <div style={{
              margin: "0 10px 10px 10px",
              paddingRight: "12px",
              color: "black",
            }}>
              <p><b>Report Details:</b> {activeMarker.name}</p>
              <button
                style={{
                  backgroundColor: "red",
                  color: "white",
                  padding: "5px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  border: "none",
                }}
                onClick={() => {
                  removeMarker(activeMarker.report_id);
                }}
              >
                Remove
              </button>
            </div>
          </InfoWindow>
        )}
        {carLocationData && (
          <Marker
            style={{ position: "absolute", transform: "translate(-50%, -100%)" }}
            position={{ lat: carLocationData.lat, lng: carLocationData.lng }}
            icon={{
              // url: "https://img.icons8.com/fluency/48/car-top-view.png",
              //   url: "https://img.icons8.com/?size=30&id=2436&format=png&color=000000",
              // url: "https://img.icons8.com/?size=30&id=42318&format=png&color=000000",
              url: "https://img.icons8.com/?size=30&id=7bGlJrKnisOw&format=png&color=000000",
            }}
          />
        )}
        {destinationPosition?.dest_lat && destinationPosition?.dest_lng && (
          <Marker
            style={{ position: "absolute", transform: "translate(-50%, -100%)" }}
            position={{ lat: destinationPosition.dest_lat, lng: destinationPosition.dest_lng }}
            icon={{
              url: "https://img.icons8.com/?size=30&id=2436&format=png&color=000000",
            }}
          />
        )
        }
      </GoogleMap>
    </LoadScript>
  );
};

export default MyMapComponent;
