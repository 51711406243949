import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { Box, Grid, Typography, capitalize, useTheme } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { formatDatetimeIntoLocal, textTruncate } from "../../../utils/helper";

export default function RoadInfo() {
  const theme = useTheme();
  const { roadData, carLocationData, liveAccidentScore, customTrafficDensity } = useSelector((state) => state.dashboard);
  const [currentTime, setCurrentTime] = React.useState(new Date());

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000); // Update every second

    return () => clearInterval(intervalId); // Clean up on unmount
  }, []);

  return (
    <Box>
      <Typography
        variant="h2"
        sx={(theme) => ({
          fontFamily: theme.typography.fontFamily,
          fontSize: "22px",
          lineHeight: "100%",
        })}
      >
        Road Information:
      </Typography>
      <Typography
        sx={(theme) => ({
          fontFamily: theme.typography.fontFamily,
          fontSize: "18px",
          lineHeight: "100%",
          mt: 1,
        })}
      >
        Activity at: {formatDatetimeIntoLocal(currentTime)}
      </Typography>
      <Typography
        variant="caption"
        display="flex"
        my={2}
        sx={(theme) => ({
          lineHeight: "130%",
          color: theme.palette.primary.main,
          overflow: "hidden",
          textOverflow: "ellipsis",
          textWrap: "nowrap",
          fontSize: "16px",
          fontWeight: "bold",
        })}
      >
        <FmdGoodIcon sx={{ fontSize: "16px" }} />
        {textTruncate(carLocationData?.formatted_address || "No location data", 70)}
      </Typography>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            textWrap: "nowrap",
          }}
        >
          <Typography variant="caption">Road Type:&nbsp;</Typography>
          <Typography
            variant="caption"
            sx={{
              fontWeight: "bold",
              color: (theme) => theme.palette.error.main,
            }}
          >
            {capitalize(roadData?.road_type || "N/A")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            textWrap: "nowrap",
          }}
        >
          <Typography variant="caption">Road Curvature:&nbsp;</Typography>
          <Typography
            variant="caption"
            sx={{
              fontWeight: "bold",
              color: (theme) => theme.palette.error.main,
            }}
          >
            {`${Math.round((roadData?.curvature || 0) * 100) / 100}` || "N/A"}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            textWrap: "nowrap",
          }}
        >
          <Typography variant="caption">Roundabout:&nbsp;</Typography>
          <Typography
            variant="caption"
            sx={{
              fontWeight: "bold",
              color: (theme) => (roadData?.roundabout ? theme.palette.error.main : theme.palette.primary.main),
            }}
          >
            {capitalize(String(roadData?.roundabout || "N/A"))}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            textWrap: "nowrap",
          }}
        >
          <Typography variant="caption">Traffic:&nbsp;</Typography>
          {customTrafficDensity === "default" || !customTrafficDensity ? (
            <Typography
              variant="caption"
              sx={{
                fontWeight: "bold",
                color: (theme) =>
                  roadData?.traffic_density === "low"
                    ? theme.palette.primary.main
                    : roadData?.traffic_density === "medium"
                      ? theme.palette.warning.main
                      : roadData?.traffic_density === "high"
                        ? theme.palette.error.main
                        : theme.palette.primary.main,
              }}
            >
              {capitalize(roadData?.traffic_density || "N/A")}
            </Typography>
          ) : (
            <Typography
              variant="caption"
              sx={{
                fontWeight: "bold",
                color: (theme) =>
                  customTrafficDensity === "low"
                    ? theme.palette.primary.main
                    : customTrafficDensity === "medium"
                      ? theme.palette.warning.main
                      : customTrafficDensity === "high"
                        ? theme.palette.error.main
                        : theme.palette.primary.main,
              }}
            >
              {capitalize(customTrafficDensity || "N/A")}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            textWrap: "nowrap",
          }}
        >
          <Typography variant="caption">Risk Level:&nbsp;</Typography>
          <Typography
            variant="caption"
            sx={{
              fontWeight: "bold",
              color: (theme) =>
                liveAccidentScore <= 33
                  ? theme.palette.primary.main
                  : liveAccidentScore > 33 && liveAccidentScore <= 66
                    ? theme.palette.warning.main
                    : liveAccidentScore > 66
                      ? theme.palette.error.main
                      : theme.palette.primary.main,
            }}
          >
            {liveAccidentScore <= 33
              ? "Low"
              : liveAccidentScore > 33 && liveAccidentScore <= 66
                ? "Medium"
                : liveAccidentScore > 66
                  ? "High"
                  : "Normal"}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            textWrap: "nowrap",
          }}
        >
          <Typography variant="caption">Driver Behavior:&nbsp;</Typography>
          <Typography
            variant="caption"
            sx={{
              fontWeight: "bold",
              color: (theme) =>
                roadData?.vehicle_speed > (roadData?.road_speed_limit || 45) ? theme.palette.error.main : theme.palette.success.main,
            }}
          >
            {roadData?.vehicle_speed > (roadData?.road_speed_limit || 45) ? "Speeding" : "Normal"}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            textWrap: "nowrap",
          }}
        >
          <Typography variant="caption">Recommended Action:&nbsp;</Typography>
          <Typography
            variant="caption"
            sx={{
              fontWeight: "bold",
              color: (theme) =>
                roadData?.vehicle_speed > (roadData?.road_speed_limit || 45) ? theme.palette.error.main : theme.palette.success.main,
            }}
          >
            {roadData?.vehicle_speed > (roadData?.road_speed_limit || 45) ? "Slow Down" : "Keep Driving"}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
