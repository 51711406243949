import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material";
import React from "react";

const PrimaryLoadingButton = ({ sx, children, isDisabled = false, isLoading = false, ...rest }) => {
  const theme = useTheme();

  return (
    <LoadingButton
      type="submit"
      disabled={isDisabled}
      disableRipple
      variant="contained"
      endIcon={<></>}
      loading={isLoading}
      loadingPosition="end"
      sx={{
        ...theme.typography.bigButton,
        color: theme.palette.background.default,
        background: theme.palette.primary.main,
        borderColor: "#404040",
        boxShadow: theme.dashboardContainer.primaryButtonBoxShadow,
        borderRadius: "4px",
        "&:hover": {
          backgroundColor: theme.palette.primary.light,
        },
        "&:active": {
          backgroundColor: theme.palette.action.active,
          boxShadow: "inset 0px 4px 6px rgba(1, 19, 35, 0.4)",
        },
        "&.Mui-disabled": {
          backgroundColor: theme.palette.action.disabled,
          color: theme.palette.background.default,
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </LoadingButton>
  );
};
export default PrimaryLoadingButton;
