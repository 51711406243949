import { Typography } from "@mui/material";
import { Field, useField } from "formik";
import CustomInputField from "./CustomInputField";

export default function FormikInputField(props) {
  const { errorText, ...rest } = props;
  const [field, meta] = useField(props);
  return (
    <Field
      component={CustomInputField}
      size="small"
      variant="outlined"
      value={field.value}
      autoComplete="off"
      error={meta.touched && Boolean(meta.error)}
      helperText={
        meta.touched && meta.error ? (
          meta.error
        ) : (
          <Typography component={"span"} ml={- 1
          } variant="caption1" >
            {props.initialhelper}
          </Typography>
        )
      }
      {...field}
      {...rest}
    />
  );
}
