import { createSlice } from "@reduxjs/toolkit";

import { deleteCrash, getRouteBetweenPoints, reportCrash, reportHotspot, saveInferenceScoreFeedback, setDestination } from "./dashboardThunk";

const initialState = {
    carGeneralData: null,
    carLocationData: null,
    weatherData: null,
    roadData: null,
    customTrafficDensity: null,
    liveAccidentScore: 0,
    isLoading: false,
    coOrdinates: null,
};

const dashboardSlice = createSlice({
    name: "dashboardSlice",
    initialState: initialState,
    reducers: {
        updateCarGeneralData: (state, action) => {
            state.carGeneralData = { ...state.carGeneralData, ...action.payload };
        },
        updateCarLocationData: (state, action) => {
            state.carLocationData = { ...state.carLocationData, ...action.payload };
        },
        updateWeatherData: (state, action) => {
            state.weatherData = { ...state.weatherData, ...action.payload };
        },
        updateRoadData: (state, action) => {
            state.roadData = { ...state.roadData, ...action.payload };
        },
        updateLiveAccidentScore: (state, action) => {
            state.liveAccidentScore = action.payload;
        },
        updateCustomTrafficDensity: (state, action) => {
            state.customTrafficDensity = action.payload;
        },
        updateCoOrdinates: (state, action) => {
            state.coOrdinates = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(setDestination.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(setDestination.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(setDestination.rejected, (state, action) => {
                state.isLoading = false;
            });

        builder
            .addCase(reportCrash.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(reportCrash.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(reportCrash.rejected, (state, action) => {
                state.isLoading = false;
            });

        builder
            .addCase(deleteCrash.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteCrash.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(deleteCrash.rejected, (state, action) => {
                state.isLoading = false;
            });

        builder
            .addCase(saveInferenceScoreFeedback.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(saveInferenceScoreFeedback.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(saveInferenceScoreFeedback.rejected, (state, action) => {
                state.isLoading = false;
            });

        builder
            .addCase(getRouteBetweenPoints.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getRouteBetweenPoints.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(getRouteBetweenPoints.rejected, (state, action) => {
                state.isLoading = false;
            });

        builder
            .addCase(reportHotspot.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(reportHotspot.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(reportHotspot.rejected, (state, action) => {
                state.isLoading = false;
            });
    },
});

export default dashboardSlice;
export const dashboardActions = dashboardSlice.actions;