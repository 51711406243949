import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import CustomDivider from "../../../components/CustomDivider";

export default function WeatherInfo() {
  const { weatherData } = useSelector((state) => state.dashboard);

  return (
    <Box>
      <Typography
        variant="h2"
        sx={(theme) => ({
          fontFamily: theme.typography.fontFamily,
          fontSize: "22px",
          lineHeight: "100%",
        })}
      >
        Weather Information:
      </Typography>
      <CustomDivider />
      <Grid container spacing={1} mt={1}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            textWrap: "nowrap",
          }}
        >
          <Typography variant="caption">Humidity:&nbsp;</Typography>
          <Typography
            variant="caption"
            sx={{
              fontWeight: "bold",
              color: (theme) => theme.palette.primary.main,
            }}
          >
            {`${Math.round((weatherData?.humidity || 0) * 100) / 100} %` || "N/A"}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            textWrap: "nowrap",
          }}
        >
          <Typography variant="caption">Pressure:&nbsp;</Typography>
          <Typography
            variant="caption"
            sx={{
              fontWeight: "bold",
              color: (theme) => theme.palette.primary.main,
            }}
          >
            {`${Math.round((weatherData?.pressure || 0) * 100) / 100} in` || "N/A"}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            textWrap: "nowrap",
          }}
        >
          <Typography variant="caption">Visibility:&nbsp;</Typography>
          <Typography
            variant="caption"
            sx={{
              fontWeight: "bold",
              color: (theme) => theme.palette.primary.main,
            }}
          >
            {`${Math.round((weatherData?.visibility || 0) * 100) / 100} mi` || "N/A"}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            textWrap: "nowrap",
          }}
        >
          <Typography variant="caption">Weather Condition:&nbsp;</Typography>
          <Typography
            variant="caption"
            sx={{
              fontWeight: "bold",
              color: (theme) => theme.palette.primary.main,
            }}
          >
            {weatherData?.weather_condition || "N/A"}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            textWrap: "nowrap",
          }}
        >
          <Typography variant="caption">Wind Direction:&nbsp;</Typography>
          <Typography
            variant="caption"
            sx={{
              fontWeight: "bold",
              color: (theme) => theme.palette.primary.main,
            }}
          >
            {weatherData?.wind_direction || "N/A"}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            textWrap: "nowrap",
          }}
        >
          <Typography variant="caption">Wind Speed:&nbsp;</Typography>
          <Typography
            variant="caption"
            sx={{
              fontWeight: "bold",
              color: (theme) => theme.palette.primary.main,
            }}
          >
            {`${Math.round((weatherData?.wind_speed || 0) * 100) / 100} mph` || "N/A"}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            textWrap: "nowrap",
          }}
        >
          <Typography variant="caption">Precipitation:&nbsp;</Typography>
          <Typography
            variant="caption"
            sx={{
              fontWeight: "bold",
              color: (theme) => theme.palette.primary.main,
            }}
          >
            {`${Math.round((weatherData?.precipitation || 0) * 100) / 100} %` || "N/A"}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
