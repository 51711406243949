import { Box, Grid, Paper, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { GoAlert } from "react-icons/go";
import { io } from "socket.io-client";
import CustomButton from "../../components/CustomButton";

import { useDispatch, useSelector } from "react-redux";
import { dashboardActions } from "../../store/dashboard/dashboardSlice";
import { setDestination } from "../../store/dashboard/dashboardThunk";
import { WS_BASE_URL } from "../../utils/constants";
import FeedbackWidget from "./components/Feedback/FeedbackWidget";
import FeedbackButtons from "./components/FeedbackButtons";
import GeneralInfo from "./components/GeneralInfo";
import KnownIssues from "./components/KnownIssues";
import MyMapComponent from "./components/MyMapComponent";
import RoadInfo from "./components/RoadInfo";
import TrafficDensitySelector from "./components/TrafficDensitySelector";
import WeatherInfo from "./components/WeatherInfo";

const DEFAULT_DESTINATION_POSITION = { dest_lat: 0, dest_lng: 0 };

export default function Dashboard({ isFeedback = false }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { carGeneralData, carLocationData, liveAccidentScore } = useSelector((state) => state.dashboard);

  const [destinationPosition, setDestinationPosition] = React.useState(DEFAULT_DESTINATION_POSITION);
  const [alertMessage, setAlertMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [alertType, setAlertType] = useState(null); // [error, warning]

  React.useEffect(() => {
    const socket = io(`${WS_BASE_URL}/`);

    socket.on("connect", () => {
      console.log("connected to websocket");
    });

    socket.on("disconnect", () => {
      console.log("disconnected from websocket");
    });

    socket.on("error", (err) => {
      console.error("Socket encountered error: ", err.message, "Closing socket");
      socket.disconnect();
    });

    socket.on("data", (data) => {

      dispatch(dashboardActions.updateCarGeneralData({
        vehicle_speed: data["data"]["vehicle_speed"] || 0,
        vehicle_accelerator_pos_d: data["data"]["vehicle_accelerator_pos_d"] || 0,
        vehicle_accelerator_pos_e: data["data"]["vehicle_accelerator_pos_e"] || 0,
        vehicle_accelerator_pos_a: data["data"]["vehicle_accelerator_pos_a"] || 0,
        vehicle_rpm: data["data"]["vehicle_rpm"] || 0,
        vehicle_relative_throttle_pos: data["data"]["vehicle_relative_throttle_pos"] || 0,
        vehicle_relative_accel_pos: data["data"]["vehicle_relative_accel_pos"] || 0,
        vehicle_engine_load: data["data"]["vehicle_engine_load"] || 0,
        vehicle_fuel_status: data["data"]["vehicle_fuel_status"] || 0,
        vehicle_run_time: data["data"]["vehicle_run_time"] || 0,
      }));

      dispatch(dashboardActions.updateCarLocationData({
        lat: data["lat"],
        lng: data["lng"],
      }));

      dispatch(dashboardActions.updateWeatherData({
        weather_condition: data["data"]["weather_condition"],
        humidity: data["data"]["humidity"],
        wind_speed: data["data"]["wind_speed"],
        wind_direction: data["data"]["wind_direction"],
        pressure: data["data"]["pressure"],
        visibility: data["data"]["visibility"],
        precipitation: data["data"]["precipitation"],
      }));

      dispatch(dashboardActions.updateRoadData({
        road_type: data["data"]["road_type"],
        curvature: data["data"]["curvature"],
        roundabout: data["data"]["roundabout"],
        traffic_density: data["data"]["traffic_density"],
        vehicle_speed: data["data"]["vehicle_speed"],
        road_speed_limit: data["data"]["road_speed_limit"],
        junction: data["data"]["junction"],
        station: data["data"]["station"],
      }));

      dispatch(dashboardActions.updateLiveAccidentScore(data["accident_probability"]));

      console.log(data)
      if (data["error"]) {
        setAlertMessage(data["error"]);
      }
      else {
        setAlertMessage("");
      }

      if (data["warning"]) {
        setWarningMessage(data["warning"])
      }
      else {
        setWarningMessage("");
      }
    });

    socket.on('coords', (data) => {
      dispatch(dashboardActions.updateCoOrdinates(data));
      dispatch(dashboardActions.updateCarLocationData({ lat: data["lat"], lng: data["lng"] }))
    });

    socket.on('car_location', (data) => {
      dispatch(dashboardActions.updateCarLocationData({ lat: data["lat"], lng: data["lng"] }))
    });

    socket.on('destination', (data) => {
      setDestinationPosition(data);
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('data');
      socket.off('coords');
      socket.off('car_location');
    };
  }
    , []);

  React.useEffect(() => {
    if (destinationPosition.dest_lat && destinationPosition.dest_lng) {
      dispatch(setDestination({
        lat: destinationPosition.dest_lat,
        lng: destinationPosition.dest_lng,
      }))
    }
  }, [destinationPosition]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} sx={{ display: "flex", justifyContent: "flex-start", gap: 2 }}>
          <Box>
            <img src="/skylarklab_logo_black.png" alt="logo" width={"50px"} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>
            <Typography variant="h1">Accident Hotspot Detection</Typography>
            <Typography variant="h4" fontWeight="bold" color={theme.palette.primary.main}>
              Powered by Skylark Labs
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
            <CustomButton
              onClick={() => {}}
              size="large"
              sx={{ px: [1, 3], py: 1.5, fontSize: "1.4rem", fontWeight: "bold", width: ["100%", "auto"], flexDirection: ["column", "row"] }}
            >
              <Box>Accident Awareness Score&nbsp;:&nbsp;</Box>
              <Box
                color={() =>
                  liveAccidentScore <= 33
                    ? theme.palette.primary.main
                    : liveAccidentScore > 33 && liveAccidentScore <= 66
                      ? theme.palette.warning.main
                      : liveAccidentScore > 66
                        ? theme.palette.error.main
                        : theme.palette.primary.main
                }
              >
                {carGeneralData?.vehicle_speed ? liveAccidentScore + '%': 'NA'}
              </Box>
            </CustomButton>
          </Box>
        </Grid>
      </Grid>
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8} sx={{ height: ["80vh", "90vh"] }}>
            <Paper
              sx={{
                background: theme.palette.background.default,
                boxShadow: theme.dashboardContainer.insetBoxShadow,
                borderRadius: "18px",
                paddingTop: "0px",
                height: "100%",
                overflowY: "scroll",
                py: 2,
                px: 2,
                [theme.breakpoints.down("md")]: { boxShadow: "none" },
              }}
            >
              <MyMapComponent setDestinationPosition={setDestinationPosition} destinationPosition={destinationPosition} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} sx={{ height: ["auto", "90vh"] }}>
            <Paper
              sx={{
                background: theme.palette.background.default,
                boxShadow: theme.dashboardContainer.insetBoxShadow,
                borderRadius: "18px",
                paddingTop: "0px",
                height: "100%",
                overflowY: "scroll",
                py: 2,
                px: 2,
                [theme.breakpoints.down("md")]: { boxShadow: "none" },
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 4, my: 2, mx: 2 }}>
                <RoadInfo />
                {/* {!destinationPosition?.dest_lat && !destinationPosition?.dest_lng && (
                  <Typography variant="caption" color="error">
                    Note: No destination selected, prediction won't be accurate without destination.
                  </Typography>
                )} */}
                {alertMessage !== "" && (
                  <Box sx={{ p: 2, borderRadius: "8px", background: `red`, display: "flex", alignItems: "center", gap: 1, justifyContent: "center" }}>
                    <GoAlert size={24} color="black" />
                    <Typography variant="h6" color="black">
                      {alertMessage}
                    </Typography>
                  </Box>
                )}
                {warningMessage !== "" && (
                  <Box
                    sx={{ p: 2, borderRadius: "8px", background: `yellow`, display: "flex", alignItems: "center", gap: 1, justifyContent: "center" }}
                  >
                    <GoAlert size={24} color="black" />
                    <Typography variant="h6" color="black">
                      {warningMessage}
                    </Typography>
                  </Box>
                )}

                {isFeedback && (
                  <Box
                    sx={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      textWrap: "nowrap",
                    }}
                  >
                    <TrafficDensitySelector />
                  </Box>
                )}
                {isFeedback && carLocationData && <FeedbackButtons lat={carLocationData.lat} lng={carLocationData.lng} />}
                <KnownIssues />
                <WeatherInfo />
                <GeneralInfo data={carGeneralData} />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <FeedbackWidget />
    </Box>
  );
}
