import { LoadingButton } from "@mui/lab";

const CustomFormButton = ({ sx, children, isLoading = false, ...rest }) => {
    return (
        <LoadingButton
            type="submit"
            variant="contained"
            size="large"
            endIcon={<></>}
            loading={isLoading}
            loadingPosition="end"
            sx={(theme) => ({
                // ...theme.typography.hugeButton,
                fontWeight: "bold",
                backgroundColor: theme.palette.primary.main,
                transform: "rotate(0.13deg)",
                boxShadow: theme.dashboardContainer.primaryButtonBoxShadow,
                borderRadius: "4px",
                "&:hover": {
                    backgroundColor: theme.palette.primary.light,
                },
                "&:active": {
                    backgroundColor: theme.palette.action.active,
                    boxShadow: "inset 0px 4px 6px rgba(1, 19, 35, 0.4)",
                },
                "&.Mui-disabled": {
                    backgroundColor: theme.palette.action.disabled,
                    color: theme.palette.background.default,
                },
                ...sx,
            })}
            {...rest}
        >
            {children}
        </LoadingButton>
    );
};

export default CustomFormButton;
