import React from "react";
import { ThemeProvider as MuiThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import { lightTheme, darkTheme } from "../utils/theme";
import { useSelector } from "react-redux";

const ThemeProvider = ({ children }) => {
  const [isDarkTheme, setIsDarkTheme] = React.useState(true);
  const darkMode = useSelector((state) => state.theme.isDarkTheme);

  React.useMemo(() => {
    if (darkMode) {
      setIsDarkTheme(true);
    } else {
      setIsDarkTheme(false);
    }
  }, [darkMode]);

  const theme = isDarkTheme ? createTheme(darkTheme) : createTheme(lightTheme);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
