import { Button, useTheme } from "@mui/material";
import React from "react";

export default function CustomIconButton({ children, sx, ...rest }) {
  const theme = useTheme();

  return (
    <Button
      variant="contained"
      disableRipple
      sx={{
        border: `0.5px solid ${theme.additionalColors.lightBorder}`,
        background: theme.dashboardContainer.closeButtonColor,
        boxShadow: theme.dashboardContainer.closeButtonBoxShadow,
        borderRadius: "3px",
        minWidth: (theme) => theme.spacing(3),
        width: (theme) => theme.spacing(3),
        height: (theme) => theme.spacing(3),
        padding: "6px",
        color: theme.palette.text.main,
        "&:hover": {
          background: theme.dashboardContainer.closeButtonColor,
          boxShadow: theme.dashboardContainer.customButtonActiveBoxShadow,
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Button>
  );
}
